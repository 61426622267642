.shade {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(28, 28, 28, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	pointer-events: all;
}

.blurred {
	background-image: linear-gradient(45deg, rgba(28, 28, 28, 1), transparent);
	background-color: rgba(28, 28, 50, 0.5);
	backdrop-filter: blur(4px);
}

.container {
	width: 38.1%;
	height: 38.1%;
	position: absolute;
	padding: 5px;
	z-index: 3;
	background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.05), rgba(150, 150, 200, 0.01));
	background-color: rgba(15, 15, 25, 0.8);
	display: flex;
	align-items: stretch;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
}

.container.tall {
	height: 90%
}
.container.wide {
	width: 75%;
}

.alertBox {
	padding: 10px;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
		'title'
		'textBox'
		'buttons';
	z-index: 3;
	border-radius: 15px;
	overflow: hidden;
	width: 100%;
}

.title {
	grid-area: title;
	display: flex;
	justify-content: center;
	background-color: #1e1e1e;
	box-sizing: border-box;
	padding: 5px;
	border-radius: 15px;
}

.error {
	color: red;
}

.textBox {
	grid-area: textBox;
	margin: 5px;
	padding: 5px 30px;
	display: block;
	text-align: left;
	overflow: scroll;
	font-weight: 300;
	box-sizing: border-box;
	width: unset;
}

.textBox ul {
	padding-inline-start: 20px;
}

.response {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: row;
}

.buttonRow {
	grid-area: buttons;
	display: grid;
	column-gap: 10px;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
}
.buttonRow:last-child button {
	padding: 8px;
}

.buttonRow > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.buttonRow > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.buttonRow button {
	border: none;
    color: white;
}

button.NO {
	background-color: darkred
}

button.OK {
	grid-column-end: yes;
}

button.ERROR {
	background-color: darkred;
}

button.YES {
	background-color: darkgreen;
}

.loading {
	font-size: 5vw;
}

.loading > svg {
	animation: rotate 1s linear infinite;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
}