/* Depth: 1 */
.stage {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.stage a {
	color: unset;
}

/* Depth: 2 */
.col1 {
	display: grid;
	grid-template-rows: 1fr auto;
}
.col2 {
	height: 100%;
	display: grid;
	grid-auto-rows: auto;
}

/* Depth: 3 */

.stage > div:global(.container) {
	height: 100%;
	background-color: #2d2d2d;
    border-radius: 15px;
}

.editingArea {
	grid-row: 1 / span 1;
	background-color: #2d2d2d;
    border-radius: 15px;
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	row-gap: 10px;
}

.textarea {
	border-radius: 15px;
    max-width: 100%;
    width: 100%;
    background-color: #212121;
    height: 100%;
    color: #fff;
    position: relative;
	padding: 10px;
    box-sizing: border-box;
	user-select: auto;
	outline: none;
}

.preview {
	text-align: justify;
	display: grid;
	grid-template-rows: auto 1fr;
}
.previewWrapper {
	font-family: Josefin Sans, sans-serif;
	word-wrap: break-word;
	padding: 10px;
	box-sizing: border-box;
	font-weight: 300;
}

.buttons {
	grid-row: 2 / span 1;
	min-height: 1.5em;
}

.buttons > div {
	display: grid;
	grid-auto-flow: column;
	column-gap: 10px;
	border-radius: 15px;
	overflow: hidden;
}

button.submit, div.submit {
	padding: 10px 5px;
	height: unset;
	border: none;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: green;
}

button.reset {
	background-color: rgb(0, 0, 49);
}

button.clear {
	background-color: darkred;
}