/* Depth: 1 */
.stage {
	width: 100%;
	height: 100%;
	position: relative;
}

/* Depth: 2 */
.columns {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	column-gap: 60px;
	align-items: center;
	margin: 20px;
	width: max-content;
}

/* Depth: 3 */
.column {
	display: grid;
	row-gap: 20px;
	height: 100%;
}

/* Depth: 4 */
.matchUp {
	display: grid;
	row-gap: 2px;
	grid-template-rows: 1fr 1fr;
	height: max-content;
	background-color: #2a2a2a;
	border-radius: 15px;
	padding: 5px;
	position: relative;
}

/* Depth: 5 */
.white, .black {
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	background-color: #1e1e1e;
	border-radius: 15px;
	padding: 0 8px;
	box-sizing: border-box;
	position: relative;
}
.white svg, .black svg {
	font-size: x-small;
}

/* Depth: 6 */
div.playerInfo {
	text-align: left;
	align-items: center;
	box-sizing: border-box;
	padding-right: 45px;
	white-space: nowrap;
}
div.playerInfo:hover {
	background-color: unset;
}

.matchUp div[class^=components_resultsBridge] {
	width: unset;
	align-self: center;
	grid-auto-flow: row;
	position: absolute;
	right: 1.5ch;
	z-index: 3;
	font-size: small;
	row-gap: 10%;
}
.matchUp div[class^=components_resultsBridge]:hover {
	background-color: unset;
}
.matchUp div[class^=components_neutral], .playerInfo div[class^=components_centre] {
	display: none;
}
.matchUp div[class^=components_neutral], .playerInfo div[class^=components_centre] {
	display: none;
}

/* Lines */

.winner, .all {
	width: 5px;
	height: 5px; 
	border-radius: 50%;
	background-color: gold;
	content: ' ';
	z-index: 1;
	position: absolute;
}
.all {
	left: -1ch;
}
.winner {
	right: -1ch;
}

.connectors {
	position: absolute;
	top: 0px;
	left: 0px;
}

.line {
	stroke-width: 2px;
	stroke: gold;
	fill: transparent;
}