/* Depth: 1 */

.stage {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	column-gap: 20px;
	height: 100%;
	overflow: hidden;
	padding-bottom: 10px;
	box-sizing: border-box;
}

/* Depth: 2 */

.description {
	margin-bottom: 10px;
	font-size: small;
}

.col1, .col2, .col3 {
	display: grid;
	height: 100%;
	overflow: hidden;
	grid-template-rows: auto 1fr;
	row-gap: 10px;
}

/* Depth: 3.1 */

.row, .title {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-auto-flow: column;
	column-gap: 7px;
	min-height: 32px;
}
div.header {
	transition: grid-template-columns 0.5s ease;
	height: 100%;
    background-color: rgb(69, 69, 69);
    display: flex;
    align-items: center;
	justify-content: center;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
div.row.expanded {
	grid-template-columns: 50px 1fr;
}
div.row.expanded .header {
	font-size: 0px;
}
div.row.expanded .header * {
	pointer-events: none;
}
div.searchButton {
	padding: 0px;
}

/* Depth: 3.2 */

.templates {
	display: grid;
	height: max-content;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: auto;
	padding-right: 10px;
	column-gap: 10px;
	row-gap: 10px;
	box-sizing: border-box;
}

.templates > div {
	height: 190px;
	background-color: rgba(40, 40, 75, 0.5);
	border-radius: 15px;
	padding: 10px;
	display: grid;
	grid-template-rows: 150px 1fr;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.unrepresented {
	opacity: 0.5;
}
.large {
	font-size: x-large;
	grid-template-rows: 150px;
}
.selected:global(.button) {
    background-color: rgb(60, 60, 100);
}
.templates > div > img {
	height: 80px;
	border-radius: 5px;
	justify-self: center;
}
.templates img[data-custom-flag] {
	transform: scaleX(0.66);
}

.clear {
	grid-column: 1 / span 1;
}

/* Depth: 3.3 */

.listWrapper {
	height: 100%;
}
.list {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 5px;
	padding: 5px;
	box-sizing: border-box;
	height: max-content;
}

div.player {
	border-radius: 5px;
	min-height: 2em;
	height: unset;
	z-index: 1;
}

.title {
	grid-template-columns: 1fr auto auto;
}