.profileContainer {
	height: 100%;
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
}
.profileContainer::-webkit-scrollbar {
	width: 10px;
	background-color: rgba(85, 85, 85, 0.3);
}
.profileContainer::-webkit-scrollbar-track {
	background-color: transparent;
}
.profileContainer::-webkit-scrollbar-corner {
	display: none;
}
.profileContainer::-webkit-scrollbar-button {
	display: none;
}
.profileContainer::-webkit-resizer {
	display: none;
}
.profileContainer::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	border-radius: 15px;
}
.profileContainer::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}
.profileContainer.skel {
	color: transparent;
}

div.roundRow {
	grid-template-columns: 30% 70%;
}

.row {
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 3;
	-ms-grid-row-span: 3;
	width: 100%;
	white-space: pre-wrap;
	display: grid;
	grid-template-columns: 38.2% auto;
	grid-auto-flow: column;
	column-gap: 2px;
	align-items: stretch;
	margin: 2px 0;
	/*transition: max-height 0.5s ease;*/
}

.resultsRow {
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 3;
	-ms-grid-row-span: 3;
	width: 100%;
	height: 100%;
	white-space: pre-wrap;
	display: grid;
	grid-template-columns: 10% auto 10% 10%;
	column-gap: 2px;
	align-items: start;
	overflow: hidden;
}

div.toggleKey:hover {
	background-color: #585858;
}

.desc {
	width: 100%;
	font-size: small;
	text-align: left;
	padding: 0 1em;
	word-wrap: break-word;
	box-sizing: border-box;
	transition: max-height ease 0.333s;
}
.li {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 10px;
}
.desc img {
	max-height: 200px;
}
.desc a:link, .desc a:active, .desc a:visited {
	color: white;
}
.desc :global(.switcher) {
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
}

.help {
	border-color: transparent transparent rgba(160, 160, 160, 0.5) transparent;
	border-width: 0 0 1.5em 2.5em;
	cursor: pointer;
}
.help > * {
	position: absolute;
	top: 0.6em;
	right: 0em;
	height: 0.8em;
	opacity: 0.8;
}

:global(#poolButton) {
	margin: 1em 0;
	height: 20px;
	background-color: darkblue;
	cursor: pointer;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bonusRow {
    justify-items: stretch;
	grid-template-columns: auto repeat(2, 1fr) 15% 15%;
	grid-template-areas:
		'round MP GP edit submit';
}
.bonusRow:not(.matchOnly) div:first-child {
	padding-right: 16px;
}
.bonusRow.matchOnly {
	grid-template-areas:
		'round round MP edit submit';
}

div.editable, div.editableField {
	background-color: #4b4b4b;
	display: flex;
	align-items: center;
}
div.editable.false {
	background-color: indianred;
}
div.editable.true {
	background-color: darkolivegreen;
}

div.editableField, input.input {
	font-size: 13.3333px;
	text-align: left;
}

input.input, div.editable:hover, div.editableField:hover {	
	background-color: #8b8b8b;
}

.sectionListWrapper {/*
	overflow: hidden;
	transform: scaleY(-1);*/
	transition: height 0.5s ease;
}
/*
.sectionListWrapper > * {
	transform: scaleY(-1);
}*/

.sectionList {
	position: relative;
	top: 0%;
}

.expanded {
	display: initial;
}

.sectionToggle {
	padding: 0 10px;
    cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.sectionToggle > * {
    transition: transform 0.25s;
}

div.semiPresent {
	background: repeating-linear-gradient(
		45deg,
		rgb(33, 56, 56),
		rgb(33, 56, 56), 10px,
		rgb(33, 33, 33) 10px,
		rgb(33, 33, 33) 20px
	);
}

div.playerLine {
    grid-template-columns: 85% 15%;
}

input.input {
    width: 85%;
}

button.cancel {
    grid-column: 2 / span 1;
	-ms-grid-column-span: 1;
}

.profilePictureFrame {
	grid-area: profile;
	display: flex;
	align-items: center;
}

.profilePicture {
	width: 100%;
	max-width: 150px;
}

.infoSection {
	border-radius: 15px;
	overflow: hidden;
}

div.close {
	background-color: indianred;
}
div.closeButton {
	padding: 5px;
	height: unset;
	border-radius: 15px;
	margin: 5px;
}

div.closeButton > svg {
	justify-self: left;
}

div.name {
	font-weight: bold;
	padding: unset;
	grid-column: 1 / -1;
}

.scoreSection {
	width: 100%;
	display: grid;
	column-gap: 2px;
	grid-template-columns: 30.8% repeat(3, 1fr);
	grid-template-areas:
		'round MP GP submit'
}
.scoreSection.matchOnly {
	grid-template-areas:
		'round round MP submit'
}

.scoreSection > input {
	width: unset;
	overflow: hidden;
}
.scoreSection > button {
	width: 100%;
}

.tieBreaks {
	border-radius: 15px;
	display: grid;
	grid-auto-flow: row;
	row-gap: 2px;
	padding: 2px;
}

div.divider {
	min-height: 1em;
	background-color: rgb(33, 56, 56);
	background: repeating-linear-gradient(45deg,
		rgb(22, 45, 45),
		rgb(22, 45, 45), 10px,
		rgb(33, 56, 56) 10px,
		rgb(33, 56, 56) 20px
	);
	grid-column: span 2;
	justify-content: center;
}
.tb:hover {
	background-color: rgb(88, 88, 88);
}

.baseContainer {
	display: grid;
	grid-template-columns: 150px auto;
	grid-template-areas:
		'profile description';
	column-gap: 5px;
	row-gap: 5px;
	min-height: 150px;
}
.description {
	display: grid;
	grid-area: description;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	grid-template-areas:
		'textarea textarea'
		'reset submit';
	column-gap: 5px;
	row-gap: 5px;
	width: 100%;
}
div.descriptionDiv {
	white-space: unset;
	align-items: start;
	justify-content: start;
}
.description textarea {
	grid-area: textarea;
	border: 1px grey solid;
	border-radius: 5px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: left;
	font-size: 13.333px;
}
.description button:global(.reset) {
	grid-area: reset;
}
.description button:global(.submit) {
	grid-area: submit;
}

.bottomBanner {
	grid-area: SettingsBar;
	position: relative;
	animation: slide-in-from-right 1s ease-out;
}

@keyframes slide-in-from-right {
	from {
		right: -100%;
	}
	to {
		right: 0%;
	}
}

.fsStage {
	grid-column: 1 / -1;
	display: grid;
	grid-template-rows: auto 1fr;
	width: 100%;
	height: 100%;
	grid-row: 1 / -1;
	padding: '0 20px';
}

div.fs {
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	width: max-content;
	column-gap: 10px;
}
div.fs > * {
	white-space: nowrap;
	word-break: keep-all;
}
div.fs :global(.fieldBox) {
	overflow-x: unset;
}
div.fs > div[class^='components_sectionContainer'] {
	max-width: 38.1vw;
	height: max-content;
}