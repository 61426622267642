div.container {
	animation: centre-split 0.333s ease;
	width: 45%;
	min-width: 500px;
}

@keyframes centre-split {
	from {
		transform: scaleY(0)
	}
	to {
		transform: scaleY(1)
	}
}

.section {
	margin: 1em 0 0.5em 0;
	font-weight: bold;
}

.content div:global(.keyField) {
	background-color: transparent;
}

.content div[class^='profile_row'] > div:not(:global(.keyField)),
.content div[class^='profile_row'] > div:not(:global(.keyField)) > div {
	border-radius: 15px;
	height: max-content;
	align-self: end;
}
.content form[class^='components_form'] > input {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	outline: none;
}

.content div[class^='profile_row'] {
	margin: 6px 0;
	column-gap: 6px;
}

.templates {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	margin: 10px;
	column-gap: 10px;
	row-gap: 10px;
}

.templates > div {
	height: 190px;
	background-color: rgba(40, 40, 75, 0.5);
	border-radius: 15px;
	padding: 10px;
	display: grid;
	grid-template-rows: 150px 1fr;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.templates > div > svg {
	font-size: 120px;
	justify-self: center;
}

/* Depth: 3 */

.set {
	transition: max-height 0.333s ease;
	max-height: 700px;
	overflow: hidden;
}
.set.collapsed {
	max-height: 0px;
}

.divider {
	display: grid;
	grid-template-columns: 1fr auto auto 1fr;
	column-gap: 10px;
	cursor: pointer;
	font-weight: 300;
	font-size: small;
	margin-top: 2em;
}
.divider .angle {
	transition: transform 0.333s ease;
}
.divider hr {
	width: 100%;
	height: 1px;
	background-color: rgba(150, 150, 150, 0.5);
	border: none;
}
.divider svg:not(.rotated) {
	transform: rotate(-90deg);
}