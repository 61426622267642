div.column {
	user-select: none;
	display: grid;
	grid-template-rows: 1fr auto 1fr;
	justify-content: center;
	justify-items: center;
	align-items: center;
}

div.leftColumn {
	align-items: flex-end;
}

div.rightColumn {
	align-items: flex-start;
}

/* Depth: 3 */
div.container {
	width: 100%;
	height: 100%;
	background-color: rgba(55, 55, 55, 0.5);
	animation: slide-in-from-left 1s ease-out;
	position: relative;
}

.profile {
	grid-area: Settings / Settings / Errors / Errors;
	box-sizing: border-box;
    height: 100%;
    width: 100%;    
	position: relative;
	overflow: hidden;
	padding: 5px;
    grid-column: unset;
	grid-row: unset;
	display: grid;
	grid-template-rows: auto 1fr;
}

/* Depth: 4 - lines */

.line {
	z-index: -1;
	position: absolute;
	transition: all 2s ease;
	background-color: black;
	height: 4px;
}
.quick .line {
	transition: unset;
}

.row {
	grid-column: 1 / span 2;
	-ms-grid-column-span: 2;
	width: 100%;
	background-color: rgb(25, 25, 25);
	grid-template-columns: 75% 25%;
}

.listWrapper {
	overflow: hidden;
	transform: scaleY(-1);
	transition: height 0.5s ease;
	width: 100%;
}

.listWrapper > * {
	transform: scaleY(-1);
}

div.clickButton {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	align-self: flex-start;
	align-items: center;
	justify-content: center;
}

div.clickButton:hover {
	background-color: #3c3c3c;
}

/* Depth: 5 - Dot */

.dotContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.column .dotContainer {
	align-self: flex-start;
}

.dot {
	display: flex;
	margin: 20px 0;
	font-size: 30px;
	padding: 5px;
	align-items: center;
	justify-content: center;
	border: 4px solid #0c0c0c;
	box-sizing: border-box;
	border-radius: 30px;
	background-color: #1e1e1e;
	background-image: repeating-linear-gradient(45deg,
		#1e1e1e8a,
		#1e1e1e8a, 10px,
		#2a2a2a8a 10px,
		#2a2a2a8a 20px
	);
	cursor: pointer;
}
.column .dot {
	flex-direction: column;
}
.dot.reverse {
	flex-direction: row-reverse;
}
.column .dot.reverse {
	flex-direction: column-reverse;
}
.dot:global(#create) {
	background-color: rgb(0, 120, 0);
}
.dot:global(#roundRobin) {
	background-color: rgb(120, 0, 0);
}
.dot:global(#knockout) {
	background-color: rgb(0, 0, 120);
}
.dot:global(#refresh) {
	background-color: rgb(0, 0, 120);
}
.dot:global(#close) {
	background-color: rgb(120, 0, 0);
}
.dot:global(#back) {
	position: relative;
	right: -10px;
}

.dotDescription {
	font-family: 'Amatic SC', cursive;
	user-select: none;
	align-items: center;
	white-space: pre;
}

.icon {
	height: 100%;
	padding: 5px;
}
.icon:global(#FaUndoAlt) {
	font-size: 25px;
	padding: calc((30px - 25px) / 2 + 5px);
}

.dotDescription {
	max-width: 0px;
	overflow: hidden;
	transition: max-width 0.1s ease, max-height 0.1s ease;
}
.dotContainerLoaded:hover .dotDescription, .dotContainerLoaded .dotDescription.descriptionSelected {
	max-width: 35ch;
	margin: 0 10px;
}
.column .dotDescription {
	max-width: 0px;
	max-height: 0px;
}
.column .dotContainerLoaded:hover .dotDescription {
	max-height: 2em;
	margin: 0 10px;
}

div.close:hover {
	background-color: darkred;
}

/* Sidebar */

.sidebar {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-auto-flow: row;
    height: 100%;
    width: 100%;
}

@keyframes slide-in-from-left {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

div.logo {
	width: 85%;
	animation: float-down 0.5s ease-out;
}
.dotUnloaded {
	animation: expand 1.5s ease-out;
}
.quick div.logo {
	animation: unset;
}
.quick .dotUnloaded {
	animation: unset;
}

@keyframes float-down {
	from {
		top: -80px;
	}
	to {
		top: 0px;
	}
}

@keyframes expand {
	0% {
		transform: scale(0)
	}
	75% {
		transform: scale(0)
	}
	100% {
		transform: scale(1)
	}
}

@keyframes briefly-expand {
	from {
		transform: scale(1)
	}	
	to {
		transform: scale(2)
	}
}

/* Profile settings */

div.license1 {
    background-color: darkred;
}

div.license2 {
    background-color: darkgreen;
}

div.license3 {
    background-color: darkblue;
}

.scrollable {
	border-radius: 10px;
	overflow-x: hidden;
	margin: 10px 0;
}