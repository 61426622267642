/* Editable Field */

.container {
	position: relative;
	width: 100%;
    height: 100%;
    display: flex;
	flex-direction: row;
}

.editableField {
	width: 100%;
	height: 100%;
	align-self: start;
	background-color: #1e1e1e;
	box-sizing: border-box;
	border: none;
	padding: 8px;
	overflow-x: scroll;

	display: flex;
	align-items: center;
}


.shroud {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.333;
	z-index: 1;
	top: 0px;
	left: 0px;
}
.form {
	justify-content: center;
	padding: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
}

.input {
	background-color: #585858;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
	color: white;
	text-align: center;
	align-items: center;
	font-size: 13.3333px;
	width: 100%;
	height: 100%;
	padding: 8px;
	outline: none;
}

button.submitHidden {
	display: none;
}

button.any {
	width: 50%;
}

button.button {
	justify-self: center;
	align-items: center;
	justify-content: center;
	padding: 5px;
}

button.button > * {
	padding: 5px;
}

.button {
	display: flex;
	align-self: stretch;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-image: none;
	cursor: pointer;
	box-sizing: border-box;
	border: none;
	padding: 0;
	font-size: 13.3333px;
    width: 15%;
}

button.infinity, .any, button.reset {
	background-color: darkblue;
	color: white;
}

.submit {
	background-color: darkgreen;
	color: white;
	border: none;
}

.submit > img, .increment > img {
    max-height: 15px;
}

.increment {
	background-color: lightgray;
}

.cancel {
	background-color: indianred;
	color: white;
}

.clear {
	background-color: darkred;
	color: white;
}

/* Single Pairing */

.resultsBridge {
	width: 100%;
	height: 100%;
	display: grid;
	grid-auto-columns: 45px;
	grid-auto-flow: column;
	align-content: center;
	align-items: center;
	justify-content: center;
}

div:hover > .resultsBridge {
	background-color: #585858;
}

div.details {
    overflow: hidden;
    transition: height 0.5s ease;
    background-color: rgb(33, 33, 33);
    border-left: rgb(5, 5, 5) 5px solid;
	border-right: rgb(5, 5, 5) 5px solid;
}

div.details > div {
	margin: 0 5px;
	padding: 5px;
}

.resolve, .reject, .neutral {
	display: flex;
	background-image: none;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0px;
	height: 100%;
	width: 100%;
	color: white;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.neutral {
    transition: transform 0.5s ease;
}

.resolve {
	background-color: darkgreen;
	border: 2px #252526 solid;
	animation: flash-green infinite 1s alternate;
	animation-delay: 5s;
}

input.linked, input.linked:focus, input[type=number].linked:focus {
	background-color: orangered;
}
input.linked:not([value='']), input.hasLinked:not([value='']) {
	background-color: rgba(255, 68, 0, 0.5);
}

@keyframes flash-green {
	from {
		background-color: yellow;
	}
	to {
		background-color: darkgreen;
	}
}

.reject {
	background-color: darkred;
	border: 2px #252526 solid;
}

.reject:focus {
	border: 2px #252526 solid;
}

.setResult, .info {
	padding: 5px;
	box-sizing: border-box;
	color: white;
	font-weight: bold;
	justify-content: center;
    text-align: center;
	align-items: center;
	background-color: rgb(65, 150, 89);
	border: 2px #1e1e1e solid;
	font-weight: 600;
	border-radius: 5px;
}
.info {
	background-color: rgb(5, 5, 30);
	cursor: pointer;
}

.inputURL {
    text-align: left;
}

.editableField {
    background-color: rgb(44, 44, 44);
}

.centre {
	display: inline-block;
	position: relative;
	height: 100%;
	width: 100%;
	vertical-align: middle;
}

.centre:hover span:global(.tooltip) {
	opacity: 0.8;
}
.detailsContent {
    width: 100%;
    grid-column: 1 / span 3;
	-ms-grid-column-span: 3;
	
	display: grid;
	row-gap: 8px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'board'
		'undo'
		'gameLinks';
	justify-content: center;
	align-items: center;
}

.row {
	display: grid;
	grid-auto-flow: column;
	white-space: pre-wrap;
	border-radius: 15px;
	overflow: hidden;
	margin: 5px;
}
.linkRow {
	grid-template-columns: 15fr 85fr;
}
.buttonRow {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
	column-gap: 5px;
	box-sizing: border-box;
    padding: 0 5px;
}
.buttonRow > * {
	width: 100%;
}
.buttonRow > *:first-child {
	border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.buttonRow > *:last-child {
	border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

div.topBanner {
	background-color: #151515;
	width: unset;
}

.validationTable {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 5px;
	justify-content: center;
	margin: 0 20px;
}
.validationTable > div {
	padding: 5px 10px;
}

.linkSubmit {
	width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

/* Collapsable Section */

.sectionContainer {
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	margin-bottom: 5px;
}

.sectionHeader {
	display: grid;
	grid-template-columns: auto 29px;
	column-gap: 5px;
	position: relative;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.sectionHeader.withLengths {
	grid-template-columns: auto 29px 29px;
}

.sectionHeaderText {
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	padding: 0 10px;
	align-items: center;
}

.sectionListWrapper {
	transition: height 0.5s ease;
	background-color: rgb(38, 38, 38);
}

.sectionList {
	position: relative;
	top: 0%;
}

.length {
	color: rgb(150, 150, 150);
	cursor: default;
}

.expanded {
	display: initial;
}

.sectionToggle {
	padding: 0 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.sectionToggle > * {
    transition: transform 0.25s;
}

/* TabSection */

.tabSection {
	display: grid;
	grid-auto-flow: column;
	align-self: stretch;
	margin: 5px;
	column-gap: 5px;
	border-radius: 15px;
}

.tabSection > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

.tabSection > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.tab {
    box-sizing: border-box;
	padding: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.tab.alone {
	cursor: default;
}

.tab svg, .tab img {
	margin: 0 5px;
	height: 1em;
}

.tab :not(svg) {
	overflow: hidden;
}
.tab.active :not(svg) {
	max-width: 100%;
}
.tab:not(.active) :not(svg):not(img) {
	max-width: 0px;
}

.active {
	background-color: #1e1e1e;
}

.tab:not(.active) {
	background-color: #424141;
	opacity: 0.6;
	cursor: pointer;
}

div > form > input.alwaysVisible {
	visibility: visible!important;
}

div > form > input.hasLink {
	background-color: orangered;
}

/* Search */

div.searchForm {
	display: flex;
	z-index: 2;
}
div.infoBox {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	height: max-content;
	justify-content: stretch;
	align-self: stretch;
	align-items: flex-start;
}

div.searchBar {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0 5px;
	height: unset;
	min-height: 32px;
	border-radius: 15px;
}
.searchBar.alwaysHover {
	background-color: rgb(69, 69, 69);
}

div.searchBar > input {
	justify-self: stretch;
	width: 100%;
	background-color: transparent;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	color: white;
	outline: none;
}

.searchResults {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	text-align: left;
	font-weight: 300;
	font-size: small;
	padding-left: 10px;
	box-sizing: border-box;
}

.searchResults > div {
	padding: 10px 25px;
	cursor: pointer;
	border-radius: 15px;
	box-sizing: border-box;
	height: min-content;
}
.searchResults > div:focus, .searchResults > div:hover:not(:first-child) {
	background-color: rgba(88, 88, 88, 0.5);
	border: none;
	outline: none;
}