.dock {
	grid-area: Dock;
	width: 100%;
	height: 100%;
	padding: 5px;
	box-sizing: border-box;
	left: 0%;
	position: relative;
	animation: slide-in-from-left 1s ease-out both;
	animation-delay: 0.5s;
}
@keyframes slide-in-from-left {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

.stage {
	background-color: rgba(45, 45, 45);
	width: 100%;
	height: 100%;
	border-radius: 15px;
	padding: 8px 5px;
	box-sizing: border-box;
	overflow: visible!important;
	overflow-y: auto;
}

.column {
	display: grid;
	row-gap: 2px;
	height: 100%;
}

.column > *:first-of-type .button {
	padding-top: 10px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.column > *:last-of-type .button {
	padding-bottom: 10px;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.container {
	position: relative;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.container > svg {
	color: darkslategray;
}
.container .button {
	width: 100%;
	padding: 1.1vh 0;
	font-size: 2.2vh;
	background: transition 1s ease;
}
.container.active .button {
	background: repeating-linear-gradient(
		45deg,
		rgba(33, 120, 33, 0.25),
		rgba(33, 120, 33, 0.25), 10px,
		rgba(33, 33, 33, 0.25) 10px,
		rgba(33, 33, 33, 0.25) 20px
	);
}
.container.active::before {
	height: 100%;
	width: 2px;
	background-color: gold;
	content: ' ';
	position: absolute;
	left: 0px;
}
.name, .options {
	position: absolute;
	left: calc(100% + 15px);
	font-size: 14px;
	z-index: 3;
	padding: 10px;
	box-shadow: 1px 1px 2px rgb(15, 15, 15);
	opacity: 0.9;
	transition: opacity 0.1s ease;
}
.name {
	font-size: 14px;
	white-space: nowrap;
	display: flex;
	background-color: rgb(15, 15, 25);
	padding: 10px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	pointer-events: none;
}
.options {
	display: grid;
	row-gap: 6px;
	background-color: rgb(45, 24, 15);
	border-radius: 5px;
	min-width: 15ch;
	opacity: 0.9;
	transition: opacity 0.1s ease;
	transition: display 0.1s ease 0.1s;
	z-index: 1;
	font-size: large;
}
.options:not(.visible) {
	opacity: 0;
	display: none;
}
.options > div {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 8px;
	padding: 0 8px;
	justify-content: left;
	text-align: left;
}
.optionsTitle {
	font-weight: bold;
	padding: 5px;
}
.container:not(:hover) .name, .container.active .name {
	opacity: 0;
}

.sort {
	min-height: 80vh;
	margin-top: -60px;
}