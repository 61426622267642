.statusBar {
	height: 30px;
	width: 100vw;
	border: 0px;
	position: fixed;
	background-color: #353535;
	font: 400 12px sans-serif;
	color: white;
	text-align: center;
	color: lightgrey;
	-webkit-app-region: drag;
	display: grid;
	grid-template-columns: 30px auto 146px;
	grid-template-rows: 100%;
	justify-items: center;
}

.statusBar > div {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	height: 100%;
	position: absolute;
	-webkit-app-region: no-drag;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

div.favicon {
	width: 100%;
	height: 100%;
	width: auto;
	justify-content: center;
	position: relative;
}

div.favicon > img {
	box-sizing: border-box;
	height: 100%;
}

.menu {
	grid-column-start: 1;
	left: 0px;
	background-color: #353535;
}

.statusBar > div.title {
	grid-column: 1 / span 3;
	-ms-grid-column-span: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	-webkit-app-region: drag;
}

.exec {
	grid-column-start: 3;
}

.exec > div {
	width: 48.541px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.exec > div:hover {
	background-color: #656565;
}

.exec > div.close:hover {
	background-color: red;
}