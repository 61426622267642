/* Depth: 1 */

.core {
	background-color: rgba(0, 0, 0, 0.9);
}

.wrapper {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-template-areas:
		'spreadsheet fide'
		'spreadsheet ecf';
}

/* Depth: 2 */

.spreadsheet {
	grid-area: spreadsheet;
	animation: slide-in-from-left 1s ease;
}

.ecf {
	grid-area: ecf;
	animation: slide-in-from-right 1s ease;
}
.fide {
	grid-area: fide;
	animation: slide-in-from-right 1s ease;
}

.core.sole div.spreadsheet:not(.expanded),
.core.sole div.ecf:not(.expanded),
.core.sole div.fide:not(.expanded) {
	display: none;
}
.wrapper > div.expanded {
	grid-area: unset;
	grid-column: 1 / -1;
	grid-row: 1 / -1;
}


/* Depth 3.2 */

.container {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
}
.textarea {
	height: 100%;
	width: 100%;
	background-color: unset;
	color: white;
	box-sizing: border-box;
	padding: 5px;
	user-select: all;
	white-space: pre;
	border: none;
}

/* Animations */

@keyframes slide-in-from-right {
	from {
		position: relative;
		right: -100%;
	}
	to {
		position: relative;
		right: 0%;
	}
}

@keyframes slide-in-from-left {
	from {
		position: relative;
		left: -100%;
	}
	to {
		position: relative;
		left: 0%;
	}
}