/* Depth: 1 */
.core {
	grid-column: unset;
	grid-row: unset;
}
.core div:global(.button#decrement) {
	margin-left: 20px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.core div.indicator:global(.button) {
	border-radius: 0px;
	cursor: default;
	background-color: #1e1e1e;
}
.core div:global(.button#increment) {
	margin-right: 20px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

/* Depth 1.1 */

.stage {
	width: 100%;
	height: 100%;
	overflow: scroll;
	position: relative;
}

/* Depth: 2 */
.players {
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
}
.players textarea {
	height: 100%;
	width: 100%;
	word-wrap: none;
}

/* Table Styles */
.table {
	border: none;
}
.table .header:first-child {
	border-top-left-radius: 20px;
}
.table .header:last-child {
	border-top-right-radius: 20px;
}
.table tr:last-child :global(.cell):first-child {
	border-bottom-left-radius: 20px;
}
.table tr:last-child :global(.cell):last-child {
	border-bottom-right-radius: 20px;
}
.table tr:last-child :global(.cell) span {
	padding-bottom: 5px;
}
.players .table:global(.data-grid) :global(.cell) {
	padding: 5px;
	box-sizing: border-box;
	border: none;
	max-width: 250px;
	word-wrap: break-word;
}
.players .table:global(.data-grid) .header:global(.cell.read-only) {
	font-weight: bold;
	background-color: rgba(245, 245, 245, 0.05);
	color: white;
	text-align: right;
	padding: 5px 10px;
	user-select: text;
}
.header[data-locked] span::after {
	padding: 0 5px;
	content: '🛇';
	opacity: 0.3;
}
.players .table:global(.data-grid) :global(.cell.read-only) {
	background-color: rgba(0, 0, 0, 0.3);
	color: rgb(130, 130, 130);
	cursor: default;
	text-align: right;
}
.players .table:global(.data-grid) :global(.cell.read-only.selected) {
	box-shadow: none;
}
.players .table:global(.data-grid) :global(.cell.selected.editing) {
	cursor: text;
}
.cell[data-header="id"] span {
	white-space: pre;
}
.table tr {
	position: relative;
	overflow: hidden;
}
.table tr:nth-child(2n) {
	background-color: rgba(120, 0, 0, 0.2);
}
.table tr:nth-child(2n - 1) {
	background-color: rgba(0, 0, 120, 0.3);
}

.table:global(.data-grid) :global(.cell.editing) input {
	color: white;
	border: none;
	outline: none;
	font-size: unset;
	height: max-content;
}

.longSelect {
	visibility: visible;
}
.players .table tr.longSelect td:first-child {
	box-shadow: -3px 0px 0px 0px red;
}
.players .table tr:first-child td.longSelect {
	box-shadow: 0px -3px 0px 0px red;
}

/* Read-only settings */

.players.editOnly :global(.cell.read-only):not(.header), .players.editOnly .header[data-locked] {
	display: none;
}

/* Depth: 3 */
.column {
	display: grid;
	row-gap: 20px;
	height: 100%;
}