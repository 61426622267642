.background {
	height: 100%;
	width: 100%;
	background-color: rgba(55, 55, 55, 0.3);
}

/* Tournament */
.tournament {
	position: relative;
	height: 100%;
	width: 100%;
	justify-items: center;
	display: grid;
	grid-template-rows: auto 1fr auto auto;
	grid-template-columns: 70px 30.8fr 0.5px 38.4fr 0.5px 30.8fr;
	grid-template-areas:
		'Dock Players ResizerLeft InfoBar ResizerRight Settings'
		'Dock Players ResizerLeft Pairings ResizerRight Settings'
		'Dock NewPlayers ResizerLeft Pairings ResizerRight Errors'
		'Dock PlayerBar ResizerLeft PairingBar ResizerRight SettingsBar';
	overflow: hidden;
}
.resizer {
	width: 10px;
	z-index: 3;
}
.resizer:hover {
	cursor: ew-resize;
}
.resizer#left {
	grid-row: ResizerLeft;
	grid-column: 3;
}
.resizer#right {
	grid-row: ResizerRight;
	grid-column: 5;
}

/* Components */
.titleBar {
	grid-area: InfoBar;
	white-space: pre-wrap;
	width: 100%;
	position: relative;
	top: 0;
	animation: slide-in-from-top 1s ease-out;
	padding: 5px;
	box-sizing: border-box;
}

.stage {
	padding: 8px 5px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 10px;
	column-gap: 10px;
	grid-auto-flow: row;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
}

@keyframes slide-in-from-top {
	from {
		top: -100%;
	}
	top {
		top: 0%;
	}
}

.row {
	grid-column: 1 / -1;
	-ms-grid-column-span: 2;

	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	column-gap: 7px;

	min-height: 32px;
}

.row > *:only-child {
	grid-column: 1 / -1;
}
.row > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.row > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

div.header {
	height: 100%;
	background-color: #3c3c3c;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}
.header div[class^='profile_row'] > div:not(:global(.keyField)) {
	border-radius: 15px;
}
div.header > div > div.editableField {
	justify-content: center;
}

div.fixed {
	height: 100%;
	background-color: #3c3c3c;
	display: flex;
	align-items: center;
	justify-content: center;
    box-sizing: border-box;
    border: none;
    padding: 5px;
}
.round.pending { color: skyblue; }
.round.active { color: rgb(255, 255, 94); }
.round.paused { color: indianred; }
.round.finished { color: green; }

div:global(.button)#previous {
	color: indianred;
}
div:global(.button)#next {
	color: lightgreen;
}

div.headerBar {
	grid-template-columns: 1fr auto;
	transition: grid-template-columns 0.5s ease;
}
div.headerBar.expanded {
	grid-template-columns: 50px 1fr;
}
div.headerBar.expanded .header {
	font-size: 0px;
}
div.headerBar.expanded .header * {
	pointer-events: none;
}
div.statusBar {
    grid-template-columns: 1fr 4fr 1fr;
}

.form {
	width: auto
}

input.input {
	padding: 0;
	font-size: 16px;
	background-color: none;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

div.editableField {
	padding: 0;
	border: 2px rgba(0,0,0,0) solid;
	background-color: transparent;
}

div.button {
	padding: 6px 10px;
}

div.button.inactive {
	cursor: default;
	background-color: #3c3c3c;
}

div.searchButton {
	padding: 0px;
}
.searchButton > div > div[class*='components_searchBar'] {
	margin: 0px;
	height: 100%;
}

/* Profile */

@keyframes slide-in-from-right {
	from {
		right: -100%;
	}
	to {
		right: 0%;
	}
}

.profile {
	grid-area: Settings / Settings / Errors / Errors;
	box-sizing: border-box;
    height: 100%;
    width: 100%;
    
	position: relative;
	animation: slide-in-from-right 1s ease-out;
	overflow: hidden;
	padding: 5px;
}

.profileStage {
	display: grid;
	grid-template-rows: auto 1fr auto;
	white-space: pre-wrap;
	align-items: flex-start;
	justify-self: stretch;
	align-self: stretch;
	flex-shrink: 0;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
	height: 100%;
	box-sizing: border-box;
}