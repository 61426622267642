.pairings {
	grid-area: Pairings;
	height: 100%;
	width: 100%;
	animation: fade-in 2s ease-out;
	padding: 5px;
	box-sizing: border-box;
	display: grid;
}
.switch {
	align-items: start;
}

.stage {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: scroll;
	border-radius: 15px;
	background-color: rgb(45, 45, 45);
	animation: fade-in 1s ease-out;
}
.knockoutWrapper {
	padding: 10px;
	box-sizing: border-box;
	overflow-y: auto;
	display: grid;
	height: max-content;
}

.pool {
	padding: 10px 0;
}

.pairingContainer {
    height: 100%;
}
.pairingContainer div:global(.button), .pairingContainer button:global(.button) {
	margin: 10px 5px;
}
.pairingContainer::-webkit-scrollbar {
	width: 10px;
	background-color: rgba(85, 85, 85, 0.3);
}
.pairingContainer::-webkit-scrollbar-track {
	background-color: transparent;
}
.pairingContainer::-webkit-scrollbar-corner {
	display: none;
}
.pairingContainer::-webkit-scrollbar-button {
	display: none;
}
.pairingContainer::-webkit-resizer {
	display: none;
}
.pairingContainer::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	transition: background-color 2s ease;
	border-radius: 15px;
}
.pairingContainer::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}
.pairingContainer input {
	outline: none;
}

.pairing {
	display: grid;
	grid-template-columns: 3fr 135px 3fr;
	justify-items: center;
	align-items: center;
	animation: fade-in 1s ease-out;
	justify-self: stretch;
	box-sizing: border-box;
	border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
	font-size: 13.3333px;
}
.pairing:global(.tempHighlight) {
	animation: set-blue-background 5s ease;
}
@keyframes set-blue-background {
	from {
		background-color: lightblue;
	}
	to {
		background-color: unset;
	}
}
.pairing > div:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.pairing > div:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.pairing:hover .playerInfo, .pairing:hover > form {
	background-color: #585858;
}
.pairing:not(:hover) input {
	visibility: hidden;
}
.pairing form:focus input.inputResult {
	visibility: visible;
}

.titleContainer {
	animation: fade-in 2s ease-out;
}

div.topBanner {	
	margin: 5px;
	width: unset;
}

@keyframes fade-in {
	0% {
		opacity: 0
	}
	50% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}

div.playerInfo {
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}
.playerInfo.selected {
	background-color: darkblue;
}

.statusBarBackground {
	grid-area: PairingBar;
	animation: fade-in 1s ease-out 1s both;
}

div.statusBar {
	display: grid;
    grid-template-columns: 1fr 4fr 1fr;
	position: relative;
	animation: slide-in-from-bottom 1s ease-out;
}

div.switchStatusBar {
	display: grid;
	column-gap: 5px;
	position: relative;
	animation: slide-in-from-bottom 1s ease-out;
	border-radius: 15px;
	overflow: hidden;
}

@keyframes slide-in-from-bottom {
	0% {
		bottom: -100%;
	}
	50% {
		bottom: -100%;
	}
	100% {
		bottom: 0%;
	}
}

.waitButton, .finishButton, div.nextButton, .undoButton {
    display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	width: 100%;
	box-sizing: border-box;
}

div.waitButton {
	background-color: #1e1e1e;
}

div.undoButton {
	cursor: pointer;
	background-color: darkred;
}

div.nextButton {
	cursor: pointer;
	background-color: darkgreen;
	position: relative;
}

.nextButton > svg {
    animation: rotate 1s infinite linear;
}
.nextButton .remainingDropWrapper {
	position: absolute;
	bottom: 100%;
	font-size: 14px;
	opacity: 0.9;
	transition: opacity 0.1s ease;
	z-index: 3;
	/* Non-hover settings */
	display: none;
	opacity: 0;
}
.nextButton:hover .remainingDropWrapper, .remainingDrop:hover {
	display: grid;
	opacity: 1;
}

.remainingDrop {
	background-color: rgb(15, 15, 25);
	padding: 10px;
	max-height: 500px;
	display: grid;
	grid-auto-rows: auto;
	row-gap: 5px;
	margin-bottom: 15px;
	font-size: 14px;
	white-space: nowrap;
	border-radius: 5px;
	width: max-content;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

.inputResult {
	padding: 5px;
	background-color: #585858;
	box-sizing: border-box;
	border: 2px #252526 solid;
	white-space: nowrap;
	color: white;
	justify-content: center;
    text-align: center;
	align-items: center;
	border-radius: 5px;
}

.inputResult {
	background-color: #585858;
}

.submit {
	display: flex;
	align-self: stretch;
	flex-direction: row;
	justify-content: center;
	background-image: none;
	cursor: pointer;
	box-sizing: border-box;
	border: 2px #252526 solid;
	padding: 0px;
	width: 100%;
	background-color: darkgreen;
	color: white;
}

div.pairingAddButton, div.clearFiltered {
	background-color: #3c3c3c;
	border-radius: 15px;
	cursor: pointer;
	padding: 8px 0;
}

.pairingAddButton:hover {
	background-color: darkgreen;
}

.manualPairing {
	display: flex;
	align-items: stretch;
	margin: 5px;
}

.manualResultsBridge {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr repeat(3, 45px) 1fr;
	grid-template-areas:
		'white wR dash bR black';
	border-radius: 15px;
}

.scoreSpacer {
	padding: 5px;
	background-color: transparent;
	box-sizing: border-box;
	white-space: nowrap;
	color: white;
	justify-content: center;
    text-align: center;
	align-items: center;
	min-width: 45px;
	border: 2px rgb(45, 45, 45) solid;
}

.manualPairing .playerInfo {
	padding: 8px;
    background-color: #3c3c3c;
    border: none;
	box-shadow: none;
	box-sizing: border-box;
    color: white;
	background-color: #585858;
	width: 100%;
	text-align: center;
}
.playerInfo::-moz-selection {
	background-color: #1e1e1e;
  }
.playerInfo::selection {
	background-color: #1e1e1e;
}
.manualPairing .playerInfo:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.manualPairing .playerInfo:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.centre, .centre > button, .centre > div {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.centre:hover span:global(.tooltip) {
	opacity: 0.8;
}

.knockout {
	margin-top: -60px;
}