/* Depth: 1  */

.stage {
	grid-column: 1 / -1;
	grid-row: Players / PlayerBar;
	display: grid;
	grid-template-rows: auto 1fr;
	row-gap: 5px;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
}
.stage.short {
	grid-column: 1 / -2;
	overflow-x: auto;
	overflow-y: auto;
}
.stage a {
	color: unset;
}

/* Depth: 2 */

.titleContainer {
	border-radius: 15px;
    width: 100%;
    padding: 5px;
	box-sizing: border-box;
}
.titleWrapper {
	background-color: rgba(45, 45, 45);
    border-radius: 15px;
}

.container {
	height: 100%;
	background-color: #2d2d2d;
    border-radius: 15px;
}

/* Depth: 3 */

.titles {
	display: grid;
	column-gap: 10px;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	justify-items: flex-start;
	align-items: center;
	padding: 5px;
	border-radius: 15px;
	width: max-content;
	max-width: 100%;
}

.title {
	font-weight: bold;
	padding: 5px 15px;
}

.description {
	font-weight: 300;
	font-size: small;
	max-width: 50vw;
	text-align: left;
}

/* Depth: 3 */

.titles :global(.button) {
	background-color: #1e1e1e;
	border-radius: 15px;
	padding: 8px 16px;
	height: 100%;
	font-size: 14px;
	text-decoration: none;
	box-sizing: border-box;
}