

/* Depth: 2 */

.wrapper {
	width: max-content;
}

.table {
	width: max-content;
}

.td, .th {
	padding: 0;
}

.cell {
	display: flex;
	align-content: center;
	background-color: #1e1e1e;
	border: 1px #252526 solid;
	padding: 8px 15px 8px 15px;
	white-space: nowrap;
	font-size: 13.3333px;
	cursor: default;
	justify-content: center;
}

.blank {
	background-color: transparent;
	border: none;
}

.player:hover {
	background-color: #3c3c3c;
}

.edit {
	background-color: rgb(33, 56, 56);
}

div.headerCell, div.firstColumn {
	font-weight: bold;
	background-color: transparent;
	border: none;
}

div.closeButton {
	margin-left: 30px;
	justify-self: flex-end;
}
a.closeButton {
	margin-left: 30px;
	justify-self: flex-end;
}

.container {
    grid-column: 1 / span 2;
	-ms-grid-column-span: 2;
    margin: 30px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    min-width: calc(100% - 60px);
}

.submit {
    width: 100%;
}

/* SCROLLBAR */

.scrollbar {
	position: absolute;
	width: 20px;
	z-index: 1;
	background-color: white;
}

.track {
	z-index: 3;
	width: 100%;
	background-color: black;
	height: 40px;
}