.sidebar {
	white-space: pre-wrap;
	position: relative;
	animation: slide-in-from-left 1s ease-out;
	padding: 5px;
	width: 100%;
	box-sizing: border-box;
}
.sidebar.players {
	grid-area: Players;
}
.sidebar.newPlayers {
	grid-area: NewPlayers;
}

.stage {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-self: stretch;
	align-self: stretch;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
	overflow: hidden;
}

@keyframes slide-in-from-left {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

.playersSection {
	height: 100%;
	box-sizing: border-box;
	border-radius: 15px;
	padding: 5px;
	overflow-x: hidden;
}
.playersSection.skel {
	color: transparent;
}
.playersSection.skel svg, .playersSection.skel input::placeholder {
	color: transparent;
}

.playersSection::-webkit-scrollbar {
	width: 10px;
	background-color: rgba(85, 85, 85, 0.3);
}
.playersSection::-webkit-scrollbar-track {
	background-color: transparent;
}
.playersSection::-webkit-scrollbar-corner {
	display: none;
}
.playersSection::-webkit-scrollbar-button {
	display: none;
}
.playersSection::-webkit-resizer {
	display: none;
}
.playersSection::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	transition: background-color 2s ease;
	border-radius: 15px;
}
.playersSection::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}

.infoBox {
	padding: 5px;
}
.scoreSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	grid-column: 1 / span 3;
	-ms-grid-column-span: 3;
	border-radius: 15px;
	overflow: hidden;
	transition: height 1s ease;
	row-gap: 2px;
}

.playersSection form {
	border-radius: 15px;
	overflow: hidden;
}
.playersSection .isActive {
	z-index: 2;
}

.scoreSection input[type='text'] {
	flex-grow: 1;
}
.scoreSection .playerLine {
	animation: slide-down 1s ease both;
}
@keyframes slide-down {
	from {
		max-height: 0px;
	}
	to {
		max-height: 60px;
	}
}

#addPlayerForm {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.input {
	padding: 8px;
	background-color: #3c3c3c;
	border: none;
	box-shadow: none;
	color: white;
	box-sizing: border-box;
	width: 100%;
}
.isActive .input[name="firstName"] {
	padding-left: 30px;
}
.input::-moz-selection {
	background-color: #1e1e1e;
  }
.input::selection {
	background-color: #1e1e1e;
}

input[type=text]:focus,
input[type=number]:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
	background-color: #585858;
}
input[type=submit]:focus,input[type=submit]:active {
	border: none;
	outline: none;
}

input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.playerLine.skel {
	opacity: 0.31;
	margin: 5px 0;
}
.players .playerLine:not(:hover):not(:global(.hover)) .cancel,
.players .playerLine:not(:hover):not(:global(.hover)) .edit,
.players .playerLine:not(:hover):not(:global(.hover)) .activate {
	visibility: hidden;
}
div.scoreSection .playerLine:hover .cancel, div.scoreSection .playerLine:global(.hover) .cancel {
	visibility: visible;
}

.submit, .edit, .cancel, .activate, .info {
	display: flex;
	align-self: stretch;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-image: none;
	cursor: pointer;
	font-weight: bold;
	border: none;
    padding: 0px;
    color: white;   
	width: 100%;
}

.submit {
	width: 100%;
	background-color: darkgreen;
	color: white;
}
.playerLine .info {
	background-color: #585858;
	color: white;
	cursor: help;
}

.submit.null {
	background-color: grey;
	pointer-events: none;
}

.playerInfo {
	text-align: left;
	font-weight: 300;
	grid-area: firstName / firstName / lastName / lastName;
	position: relative;
	padding-left: 50px;
}
.playerInfo.full {
	grid-column: 1 / -1;
}
div.playerInfo {
	padding-left: 30px;
}
.playerInfo:global(.bold) {
	font-weight: 600;
}

.edit {
	width: 100%;
	grid-area: edit;
	background-color: gray;
}

.activate {
	width: 100%;
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-area: cancel;
	background-color: darkgreen;
}

.cancel {
	width: 100%;
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-area: cancel;
	background-color: darkred;
}

.playerLine {
	display: grid;
	column-gap: 5px;
	width: 100%;
	justify-items: start;
	border-radius: 15px;
	overflow: hidden;
	grid-template-columns: 1fr 1fr 15% 15%;
	grid-template-areas:
		'firstName lastName edit cancel';
}

.playerLine.team {
	grid-area:
		'firstName firstName edit cancel'
}

.playerLine:hover .playerInfo{
	background-color: rgb(88, 88, 88);
	z-index: 1;
}

.scoreSeparator {
	display: flex;	
	border: 2px;
	padding: 5px 0 0 0;
}

.sectionHeader {
	display: flex;
	justify-items: flex-start;
	padding: 7px;
	font-size: 13.3333px;
}

.playerInactive {
	color: darkslategrey;
}
.playerInfo::after {
	content: ' ';
	width: 10px;
	height: 10px;
	left: 10px;
	box-sizing: border-box;
	border-radius: 50%;
	position: absolute;
}
.isPlaying::after {
	background-color: var(--blue);
}
.isUnpaired::after {
	background-color: var(--red);
}

form {
	width: 100%;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

/* Bottom Banner */

.topBanner { 
	width: 100%;
	align-self: start;
	background-color: #1e1e1e;
	box-sizing: border-box;
	border: 2px #252526 solid;
	padding: 5px;
	white-space: nowrap;
}

.bottomBanner {
	grid-area: PlayerBar;
    animation: slide-in-from-left 1s ease-out;
	position: relative;
}

.striped {
	background: repeating-linear-gradient(
		45deg,
		rgba(56, 20, 20, 0.5),
		rgb(56, 20, 20, 0.5), 10px,
		rgb(20, 20, 20, 0.5) 10px,
		rgb(20, 20, 20, 0.5) 20px
	);
}
.searchResults {
	height: 120px;
}

.shroud {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.333;
	z-index: 1;
	top: 0px;
	left: 0px;
}