@import url(https://fonts.googleapis.com/css?family=Amatic+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
	color: white;
	justify-items: center;
}
*:not(.scrollable):not(.scrollable-x)::-webkit-scrollbar {
	display: none;
}
*:not(.scrollable):not(.scrollable-x) {
	scrollbar-width: none;
}

#root {
	height: 100%;
	--blue: rgb(5, 5, 30);
	--red: indianred;
}

.stage {
	height: 100vh;
	width: 100vw;
	border: 0px;
	position: fixed;
	text-align: center;
	overflow: scroll;
	background-image: url('https://web.scorchapp.co.uk/images/leaves-texture.png');
	background-color: #111;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.stage.electron {
	height: calc(100vh - 30px);
	top: 30px;
}

.shade {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(28, 28, 28, 0.5);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	z-index: 3;
	pointer-events: all;
}
.blurred {
	background-image: linear-gradient(45deg,
		rgba(28, 28, 28, 1),
		rgba(150, 150, 200, 0.1)
	);
	background-color: rgba(28, 28, 50, 0.5);
	-webkit-backdrop-filter: blur(4px);
	        backdrop-filter: blur(4px);
}
.tournamentFloat {
	position: absolute;
	z-index: 3;
}

button.null, div.null {
	background-color: grey!important;
	pointer-events: none!important;
	opacity: 0.5;
}

.scrollable {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
}
.scrollable-x:not(.scrollable) {
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
}
.scrollable.scrollable-x {
	overflow-x: auto;
	overflow-y: auto;
}
.scrollable::-webkit-scrollbar, .scrollable-x::-webkit-scrollbar {
	cursor: default;
	width: 10px;
	background-color: rgba(85, 85, 85, 0);
}
.scrollable::-webkit-scrollbar:hover, .scrollable-x::-webkit-scrollbar:hover {
	background-color: rgba(85, 85, 85, 0.2);
}
.scrollable::-webkit-scrollbar-track, .scrollable-x::-webkit-scrollbar-track {
	cursor: default;
	background-color: transparent;
}
.scrollable::-webkit-scrollbar-corner, .scrollable-x::-webkit-scrollbar-corner {
	display: none;
}
.scrollable::-webkit-scrollbar-button, .scrollable-x::-webkit-scrollbar-button {
	display: none;
}
.scrollable::-webkit-resizer, .scrollable-x::-webkit-resizer {
	display: none;
}
.scrollable::-webkit-scrollbar-thumb , .scrollable-x::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	-webkit-transition: background-color 2s ease;
	transition: background-color 2s ease;
	border-radius: 15px;
}
.scrollable::-webkit-scrollbar-thumb:hover, .scrollable-x::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}

.hidden {
	display: none!important;
}

button > * {
    pointer-events: none;
}

.bold {
	font-weight: bold;
}

.code {
	font-family: 'Courier New', Courier, monospace;
	background-color: rgba(5, 5, 5, 0.5);
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	box-sizing: border-box;
	padding: 5px;
	-webkit-user-select: all;
	    -ms-user-select: all;
	        user-select: all;
}

.skel {
	color: transparent;
}

button.button, div.button, a.button {	
	display: -webkit-flex;	
	display: flex;
	justify-self: stretch;
	height: 100%;
	white-space: pre-wrap;
	box-sizing: border-box;
	background-color: #1e1e1e;
	padding: 5px 0;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.button {
	font-size: 14px;
	height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
}
*::selection {
	background-color: rgba(199, 199, 199, 0.226);
}

.button > svg {
	padding: 0 5px;
}

div.button:hover, a.button:hover, button.button:hover {
	background-color: rgb(69, 69, 69);
}

div.fieldBox {
	display: -webkit-flex;
	display: flex;
	text-align: left;
	-webkit-align-items: center;
	        align-items: center;
    background-color: rgb(33, 56, 56);
	padding: 8px;
	white-space: break-word;
	overflow-x: scroll;
	font-size: 13.3333px;
	cursor: default;
	position: relative;
}

div.playerInfo {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	box-sizing: border-box;
	padding: 8px;
	white-space: break-word;
	height: 100%;
	font-size: 13.3333px;
	cursor: pointer;
}

div.playerInfo:hover, div.playerInfo.hover {
	background-color: rgb(88, 88, 88);
}

div.topBanner {
	width: 100%;
	-webkit-align-self: start;
	        align-self: start;
	background-color: #1e1e1e;
	box-sizing: border-box;
	padding: 5px;
	border-radius: 15px;
	min-height: 32px;
	-webkit-align-items: center;
	        align-items: center;
}

div.bottomBannerBackground {
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
}

div.bottomBanner {
    height: 100%;
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	width: 100%;
	align-self: end;
	box-sizing: border-box;
	-webkit-justify-content: stretch;
	        justify-content: stretch;
	border-radius: 15px;
	background-color: rgb(45, 45, 45);
	padding: 5px;
	box-sizing: border-box;
}
.bottomBanner > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.bottomBanner > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

input[type='submit'] {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.input {
	display: -webkit-flex;
	display: flex;
	padding: 5px 0 5px 5px;
	background-color: #585858;
	box-sizing: border-box;
	border: 2px #252526 solid;
	box-shadow: none;
	color: white;
}
.container {
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 5px;
}

.tooltip {
	background-color: black;
	color: white;
	text-align: center;
	padding: 5px;
	border-radius: 6px;
	position: absolute;
	top: -220%;
	width: 120px;
	left: calc(50% - 60px);
	opacity: 0;
	z-index: 1;
	transition: opacity 0.25s;
	pointer-events: none;
}

.tooltip::after {
	content: " ";
	position: absolute;
	top: 100%; /* At the bottom of the tooltip */
	left: 50%;
	margin-left: -15px;
	border-width: 10px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.loading > svg {
    -webkit-animation: rotate 1s infinite linear;
            animation: rotate 1s infinite linear
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}
*:focus {
	outline: none;
}

.highlighted {
	color: rgb(207, 176, 0);
}

/* Toggle Switch */

/* The switch - the box around the slider */
.toggleSwitch {
	position: relative;
	max-width: 60px;
	justify-self: center;
}

/* Hide default HTML checkbox */
.toggleSwitch input.check {
	display: none;
}

/* The slider */
.toggleSlider {
	position: relative;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 80px;
	background-color: rgb(65, 65, 65);
	transition: .4s;
	min-height: 20px;
	display: -webkit-flex;
	display: flex;
	margin: 0 10px;
}

.toggleSlider:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 30.8%;
	left: 4px;
	background-color: white;
	transition: .4s;
}

input.check:checked+.toggleSlider {
	background-color: rgb(180, 180, 180);
}

input.check:focus+.toggleSlider {
	box-shadow: 0 0 1px rgb(65, 65, 65);
}

input.check:checked+.toggleSlider:before {
	left: calc(100% - 30.8% - 4px);
}

/* Rounded sliders */
.toggleSlider.round {
	border-radius: 34px;
}

.toggleSlider.round:before {
	border-radius: 34px;
}

.switchContainer {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
}

.switchContainer > label {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.switchContainer label div:not(:first-child) {
	font-size: 14px;
	font-weight: 300;
}

button.submit, button.reset {
	padding: 10px 5px;
	height: unset;
	border-radius: 5px;
	border: none;
	color: white;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	cursor: pointer;
}
button.reset {
	background-color: rgb(0, 0, 49);
}
button.submit {
	background-color: darkgreen;
}

.triangle {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 0;
	height: 0;
	border-style: solid;
}

#chessBoard {
	-webkit-transform: scaleX(-1);
	        transform: scaleX(-1);
}

/* Images */

img#lichess {
	-webkit-filter: invert(0.8);
	        filter: invert(0.8);
}
img#ecf {
	-webkit-filter: invert(0.5);
	        filter: invert(0.5);
}

/* Components */


/* Collapsable Section */

.sectionContainer {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	border-radius: 15px;
	margin-bottom: 5px;
}
.sectionContainer:not(.scrollable) {
	overflow: hidden;
}
/* styles common to login and menu */

div.app_canvas__1MLEL {
	height: 100%;
	width: 100%;
	display: grid;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	grid-template-columns: 30% 40% 30%;
	grid-template-rows: 100%;
}


div.app_column__2WVoo {
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	height: 100%;
}

div.app_logo__b6W_t {
	width: 50%;
	max-width: 300px;
	display: grid;
	position: relative;
	grid-template: 100% / 100%;
}

div.app_logo__b6W_t hr.app_loadingLine__1xeSs {
	width: 100%;
	margin: 5% 0;
	border: 5px solid #972a15;
}

img.app_staticLogo__1-Wsi {
	width: 100%;
	position: relative;
	grid-column: 1 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}

img.app_loadingGif__2PnUi {
    position: absolute;
    width: 66.6%;
    max-width: 200px;
    -webkit-transform: rotate(-33deg);
            transform: rotate(-33deg);
    opacity: 1;
    left: 6%;
    top: 15%;
	position: relative;
	grid-column: 1 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}

img.app_crownOutline__2IPSS {
    position: absolute;
    width: 71%;
    max-width: 213px;
    -webkit-transform: rotate(-32deg);
            transform: rotate(-32deg);
    opacity: 1;
    z-index: 1;
    left: 5%;
    top: 13%;
	position: relative;
	grid-column: 1 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}

@media screen {
	.app_logo__b6W_t {
		-webkit-animation: app_fade-in__3kaKs 1 0.5s linear;
		        animation: app_fade-in__3kaKs 1 0.5s linear;
	};
	.app_loadingLine__1xeSs {
		-webkit-animation: app_grow-right__1NUcX infinite 0.68s linear;
		        animation: app_grow-right__1NUcX infinite 0.68s linear;
	}
}

@-webkit-keyframes app_fade-in__3kaKs {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}

@keyframes app_fade-in__3kaKs {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}

@-webkit-keyframes app_grow-right__1NUcX {
	from {
		-webkit-transform: rotate(0deg) scaleX(0) scaleY(1);
		        transform: rotate(0deg) scaleX(0) scaleY(1)
	}
	to {
		-webkit-transform: rotate(0deg) scaleX(1) scaleY(1);
		        transform: rotate(0deg) scaleX(1) scaleY(1)
	}
}

@keyframes app_grow-right__1NUcX {
	from {
		-webkit-transform: rotate(0deg) scaleX(0) scaleY(1);
		        transform: rotate(0deg) scaleX(0) scaleY(1)
	}
	to {
		-webkit-transform: rotate(0deg) scaleX(1) scaleY(1);
		        transform: rotate(0deg) scaleX(1) scaleY(1)
	}
}
div.login_column__14Kgt {
	height: auto;
}

div.login_container__1w_9V {
	width: 100%;
	padding: 10px;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);
	background-color: rgba(15, 15, 25, 0.9);
}

.login_nameContainer__1JJvi {
	display: grid;
	grid-auto-flow: row;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
	font-family: "Roboto", Whitney, "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding-bottom: 10px;
	-webkit-justify-content: right;
	        justify-content: right;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}
.login_name__aoGQ4 {
	font-size: 36px;
	font-weight: 600;
}
.login_description__3kLJY {
	font-size: 12px;
	font-weight: 200;
	padding: 5px;
	box-sizing: border-box;
}
.login_nameContainer__1JJvi svg {
	margin: 0 20px;
}
.login_nameContainer__1JJvi .login_copyright__-uz1N {
	font-size: 10px;
}

div.login_initialiseButton__3CXrs {
	display: grid;
	height: 100%;
	width: 100%;
	min-height: 150px;
	overflow: hidden;
}

div.login_forgottenContainer__2yR4y {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 8px;
	-webkit-column-gap: 8px;
	        column-gap: 8px;
	box-sizing: border-box;
	height: 100%;
}
.login_forgottenContainer__2yR4y > div:first-child {	
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.login_forgottenContainer__2yR4y > div:last-child {	
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

div.login_forgotten__3VFQz {
	font-size: 12px;
	background-color: #1a1a1a;
	color: grey;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	outline: none;
	box-sizing: border-box;
	cursor: pointer;
}

.login_form__1ieaR {
	display: grid;
	grid-template-rows: repeat(4, 1fr);
	grid-row-gap: 8px;
	row-gap: 8px;
	height: 100%;
}

.login_form__1ieaR > .login_input__3ze1R {
	font-size: 12px;
	height: 100%;
	width: 100%;
	overflow: hidden;
	border-radius: 15px;
	display: -webkit-flex;
	display: flex;
	padding: 5px 10px;
	background-color: #585858;
	color: white;
	border: none;
	outline: none;
	box-sizing: border-box;
}

input.login_submit__2qxJT, button.login_submit__2qxJT {
	height: 100%;
	width: 100%;
	background-color: darkgreen;
	color: white;
	-webkit-justify-content: center;
	        justify-content: center;
	border-radius: 15px;
	border: none;
	outline: none;
	cursor: pointer;
}

.login_input__3ze1R.login_back__1oP5T, .login_input__3ze1R.login_text__WDlWF {
	cursor: pointer;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 6px;
	background-color: transparent;
}

.login_loginSection__1BBMn {
	position: relative;
	overflow: hidden;
	border-radius: 15px;
}

.login_loginBackground__y9UZ2 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 100%;
	-webkit-align-items: center;
	        align-items: center;
}

.login_foreground__2Cqo8 {
	display: -webkit-flex;
	display: flex;
	position: absolute;
	width: 66.6%;
	height: 100%;
	opacity: 1;
}

.login_loginForeground__lnGgY {
	left: 0px;
	transition: all 0.68s ease-out;
}

.login_registerForeground__3D6jX {
	left: 33.3%;
	transition: all 0.68s ease-out;
}

div.login_flip__2KG2q {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: relative;
	background-color: transparent;
	opacity: 1;
	transition: opacity 0.333s ease;
}
div.login_flip__2KG2q:hover {
	background-color: unset;
}

.login_flip__2KG2q > div {
    margin: 2px 0;
}
div.login_flip__2KG2q.login_hidden__2PxIe {
	opacity: 0;
}
div.login_guest__Ns_RN {
	display: none;
	position: absolute;
	bottom: 2px;
	right: 2px;
	background-color: rgba(255, 166, 0, 0.5);
	border-radius: 15px;
	padding: 5px 10px;
	height: unset;
	font-size: x-small;
}

div.login_messageBar__6TM4O {
	display: -webkit-flex;
	display: flex;
	width: 100%;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
    border: 2px #252526 solid 0.0;
	box-shadow: none;
	-webkit-justify-content: center;
	        justify-content: center;
	color: red;
}
.name_text__3avpk {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	position: relative;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	white-space: nowrap;
	width: 28ch;
	padding-left: 5ch;
	font-size: 36px;
	font-weight: 600;
}

.name_text__3avpk > div {
	position: relative;
	overflow: hidden;
}

.name_your__1yIgS {
	display: -webkit-flex;
	display: flex;
	position: relative;
}
.name_your__1yIgS > * {
	position: relative;
}

.name_expand__3_B7w {
	-webkit-animation: name_expand__3_B7w 2s ease forwards;
	        animation: name_expand__3_B7w 2s ease forwards;
}
.name_contract__cCuPp {
	-webkit-animation: name_contract__cCuPp 1s ease forwards;
	        animation: name_contract__cCuPp 1s ease forwards;
}
.name_slideLeft__2DlrS {
	-webkit-animation: name_slide-left__3WNId 2s ease forwards;
	        animation: name_slide-left__3WNId 2s ease forwards;
}
.name_slideRight__sgw1v {
	-webkit-animation: name_slide-right__1Cg-k 1s ease forwards;
	        animation: name_slide-right__1Cg-k 1s ease forwards;
}

@-webkit-keyframes name_expand__3_B7w {
	from {
		max-width: 0px;
	}
	to {
		max-width: 14ch;
	}
}

@keyframes name_expand__3_B7w {
	from {
		max-width: 0px;
	}
	to {
		max-width: 14ch;
	}
}
@-webkit-keyframes name_contract__cCuPp {
	from {
		max-width: 14ch;
	}
	to {
		max-width: 0px;
	}
}
@keyframes name_contract__cCuPp {
	from {
		max-width: 14ch;
	}
	to {
		max-width: 0px;
	}
}

@media only screen and (max-width: 700px) {
	@-webkit-keyframes name_expand__3_B7w {
		from {
			max-width: 0px;
		}
		to {
			max-width: 18ch;
		}
	}
	@keyframes name_expand__3_B7w {
		from {
			max-width: 0px;
		}
		to {
			max-width: 18ch;
		}
	}
}

@-webkit-keyframes name_slide-right__1Cg-k {
	0% {
		right: 0%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: -100%;
	}
}

@keyframes name_slide-right__1Cg-k {
	0% {
		right: 0%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: -100%;
	}
}
@-webkit-keyframes name_slide-left__3WNId {
	0% {
		right: -100%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: 0%;
	}
}
@keyframes name_slide-left__3WNId {
	0% {
		right: -100%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: 0%;
	}
}

.name_fadeIn__3CTca {
	-webkit-animation: name_fade-in__370St 4s ease-out forwards;
	        animation: name_fade-in__370St 4s ease-out forwards;
}
.name_fade2xIn__3A3xK {
    -webkit-animation: name_fade-in__370St 6s ease-out forwards;
            animation: name_fade-in__370St 6s ease-out forwards;
}

@-webkit-keyframes name_fade-in__370St {
	0% {
		display: none;
		opacity: 0;
	}
	50% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes name_fade-in__370St {
	0% {
		display: none;
		opacity: 0;
	}
	50% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
.statusbar_statusBar__3f7HO {
	height: 30px;
	width: 100vw;
	border: 0px;
	position: fixed;
	background-color: #353535;
	font: 400 12px sans-serif;
	color: white;
	text-align: center;
	color: lightgrey;
	-webkit-app-region: drag;
	display: grid;
	grid-template-columns: 30px auto 146px;
	grid-template-rows: 100%;
	justify-items: center;
}

.statusbar_statusBar__3f7HO > div {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: left;
	        justify-content: left;
	-webkit-align-items: center;
	        align-items: center;
	height: 100%;
	position: absolute;
	-webkit-app-region: no-drag;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

div.statusbar_favicon__1Unmh {
	width: 100%;
	height: 100%;
	width: auto;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
}

div.statusbar_favicon__1Unmh > img {
	box-sizing: border-box;
	height: 100%;
}

.statusbar_menu__3nFvT {
	grid-column-start: 1;
	left: 0px;
	background-color: #353535;
}

.statusbar_statusBar__3f7HO > div.statusbar_title__3ClL5 {
	grid-column: 1 / span 3;
	-ms-grid-column-span: 3;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	white-space: nowrap;
	-webkit-app-region: drag;
}

.statusbar_exec__2fXB2 {
	grid-column-start: 3;
}

.statusbar_exec__2fXB2 > div {
	width: 48.541px;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
}

.statusbar_exec__2fXB2 > div:hover {
	background-color: #656565;
}

.statusbar_exec__2fXB2 > div.statusbar_close__3ssky:hover {
	background-color: red;
}
.tournament_background__3Lj72 {
	height: 100%;
	width: 100%;
	background-color: rgba(55, 55, 55, 0.3);
}

/* Tournament */
.tournament_tournament__1ikhA {
	position: relative;
	height: 100%;
	width: 100%;
	justify-items: center;
	display: grid;
	grid-template-rows: auto 1fr auto auto;
	grid-template-columns: 70px 30.8fr 0.5px 38.4fr 0.5px 30.8fr;
	grid-template-areas:
		'Dock Players ResizerLeft InfoBar ResizerRight Settings'
		'Dock Players ResizerLeft Pairings ResizerRight Settings'
		'Dock NewPlayers ResizerLeft Pairings ResizerRight Errors'
		'Dock PlayerBar ResizerLeft PairingBar ResizerRight SettingsBar';
	overflow: hidden;
}
.tournament_resizer__TGhcC {
	width: 10px;
	z-index: 3;
}
.tournament_resizer__TGhcC:hover {
	cursor: ew-resize;
}
.tournament_resizer__TGhcC#tournament_left__l5m_I {
	grid-row: ResizerLeft;
	grid-column: 3;
}
.tournament_resizer__TGhcC#tournament_right__3zGaQ {
	grid-row: ResizerRight;
	grid-column: 5;
}

/* Components */
.tournament_titleBar__1Kvez {
	grid-area: InfoBar;
	white-space: pre-wrap;
	width: 100%;
	position: relative;
	top: 0;
	-webkit-animation: tournament_slide-in-from-top__22vmU 1s ease-out;
	        animation: tournament_slide-in-from-top__22vmU 1s ease-out;
	padding: 5px;
	box-sizing: border-box;
}

.tournament_stage__3sbve {
	padding: 8px 5px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 10px;
	row-gap: 10px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-auto-flow: row;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
}

@-webkit-keyframes tournament_slide-in-from-top__22vmU {
	from {
		top: -100%;
	}
	top {
		top: 0%;
	}
}

@keyframes tournament_slide-in-from-top__22vmU {
	from {
		top: -100%;
	}
	top {
		top: 0%;
	}
}

.tournament_row__3K-Kn {
	grid-column: 1 / -1;
	-ms-grid-column-span: 2;

	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: 7px;
	-webkit-column-gap: 7px;
	        column-gap: 7px;

	min-height: 32px;
}

.tournament_row__3K-Kn > *:only-child {
	grid-column: 1 / -1;
}
.tournament_row__3K-Kn > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.tournament_row__3K-Kn > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

div.tournament_header__hB1Cf {
	height: 100%;
	background-color: #3c3c3c;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	font-weight: bold;
}
.tournament_header__hB1Cf div[class^='profile_row'] > div:not(.keyField) {
	border-radius: 15px;
}
div.tournament_header__hB1Cf > div > div.tournament_editableField__1rO3U {
	-webkit-justify-content: center;
	        justify-content: center;
}

div.tournament_fixed__3grCR {
	height: 100%;
	background-color: #3c3c3c;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
    box-sizing: border-box;
    border: none;
    padding: 5px;
}
.tournament_round__1fpAn.tournament_pending__1YgoJ { color: skyblue; }
.tournament_round__1fpAn.tournament_active__2SoF8 { color: rgb(255, 255, 94); }
.tournament_round__1fpAn.tournament_paused__8vcWP { color: indianred; }
.tournament_round__1fpAn.tournament_finished__g5wUB { color: green; }

div.button#tournament_previous__BLUHu {
	color: indianred;
}
div.button#tournament_next__ty8U0 {
	color: lightgreen;
}

div.tournament_headerBar__3tdMl {
	grid-template-columns: 1fr auto;
	transition: grid-template-columns 0.5s ease;
	transition: grid-template-columns 0.5s ease, -ms-grid-columns 0.5s ease;
}
div.tournament_headerBar__3tdMl.tournament_expanded__3gjYn {
	grid-template-columns: 50px 1fr;
}
div.tournament_headerBar__3tdMl.tournament_expanded__3gjYn .tournament_header__hB1Cf {
	font-size: 0px;
}
div.tournament_headerBar__3tdMl.tournament_expanded__3gjYn .tournament_header__hB1Cf * {
	pointer-events: none;
}
div.tournament_statusBar__1P6z0 {
    grid-template-columns: 1fr 4fr 1fr;
}

.tournament_form__1Pn88 {
	width: auto
}

input.tournament_input__xb1Th {
	padding: 0;
	font-size: 16px;
	background-color: none;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

div.tournament_editableField__1rO3U {
	padding: 0;
	border: 2px rgba(0,0,0,0) solid;
	background-color: transparent;
}

div.tournament_button__3FfYw {
	padding: 6px 10px;
}

div.tournament_button__3FfYw.tournament_inactive__1IuMF {
	cursor: default;
	background-color: #3c3c3c;
}

div.tournament_searchButton__p7kV3 {
	padding: 0px;
}
.tournament_searchButton__p7kV3 > div > div[class*='components_searchBar'] {
	margin: 0px;
	height: 100%;
}

/* Profile */

@-webkit-keyframes tournament_slide-in-from-right__rOjOO {
	from {
		right: -100%;
	}
	to {
		right: 0%;
	}
}

@keyframes tournament_slide-in-from-right__rOjOO {
	from {
		right: -100%;
	}
	to {
		right: 0%;
	}
}

.tournament_profile__1l3mu {
	grid-area: Settings / Settings / Errors / Errors;
	box-sizing: border-box;
    height: 100%;
    width: 100%;
    
	position: relative;
	-webkit-animation: tournament_slide-in-from-right__rOjOO 1s ease-out;
	        animation: tournament_slide-in-from-right__rOjOO 1s ease-out;
	overflow: hidden;
	padding: 5px;
}

.tournament_profileStage__3TswH {
	display: grid;
	grid-template-rows: auto 1fr auto;
	white-space: pre-wrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	justify-self: stretch;
	align-self: stretch;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
	height: 100%;
	box-sizing: border-box;
}
/* Editable Field */

.components_container__1CCB7 {
	position: relative;
	width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.components_editableField__2fs18 {
	width: 100%;
	height: 100%;
	-webkit-align-self: start;
	        align-self: start;
	background-color: #1e1e1e;
	box-sizing: border-box;
	border: none;
	padding: 8px;
	overflow-x: scroll;

	display: -webkit-flex;

	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}


.components_shroud__MrSPA {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.333;
	z-index: 1;
	top: 0px;
	left: 0px;
}
.components_form__1RjIP {
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
}

.components_input__I_Vv7 {
	background-color: #585858;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
	color: white;
	text-align: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 13.3333px;
	width: 100%;
	height: 100%;
	padding: 8px;
	outline: none;
}

button.components_submitHidden__PvrYt {
	display: none;
}

button.components_any__3pCEP {
	width: 50%;
}

button.components_button__1Gg6- {
	justify-self: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 5px;
}

button.components_button__1Gg6- > * {
	padding: 5px;
}

.components_button__1Gg6- {
	display: -webkit-flex;
	display: flex;
	-webkit-align-self: stretch;
	        align-self: stretch;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background-image: none;
	cursor: pointer;
	box-sizing: border-box;
	border: none;
	padding: 0;
	font-size: 13.3333px;
    width: 15%;
}

button.components_infinity__1rF3u, .components_any__3pCEP, button.components_reset__cTuFY {
	background-color: darkblue;
	color: white;
}

.components_submit__1SFYQ {
	background-color: darkgreen;
	color: white;
	border: none;
}

.components_submit__1SFYQ > img, .components_increment__19rbD > img {
    max-height: 15px;
}

.components_increment__19rbD {
	background-color: lightgray;
}

.components_cancel__1_2ia {
	background-color: indianred;
	color: white;
}

.components_clear__5yHRa {
	background-color: darkred;
	color: white;
}

/* Single Pairing */

.components_resultsBridge__9qnay {
	width: 100%;
	height: 100%;
	display: grid;
	grid-auto-columns: 45px;
	grid-auto-flow: column;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

div:hover > .components_resultsBridge__9qnay {
	background-color: #585858;
}

div.components_details__1FuFV {
    overflow: hidden;
    transition: height 0.5s ease;
    background-color: rgb(33, 33, 33);
    border-left: rgb(5, 5, 5) 5px solid;
	border-right: rgb(5, 5, 5) 5px solid;
}

div.components_details__1FuFV > div {
	margin: 0 5px;
	padding: 5px;
}

.components_resolve__1X_KN, .components_reject__3ymMO, .components_neutral__392Xn {
	display: -webkit-flex;
	display: flex;
	background-image: none;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0px;
	height: 100%;
	width: 100%;
	color: white;
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.components_neutral__392Xn {
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.components_resolve__1X_KN {
	background-color: darkgreen;
	border: 2px #252526 solid;
	-webkit-animation: components_flash-green__2Wczz infinite 1s alternate;
	        animation: components_flash-green__2Wczz infinite 1s alternate;
	-webkit-animation-delay: 5s;
	        animation-delay: 5s;
}

input.components_linked__3nC4k, input.components_linked__3nC4k:focus, input[type=number].components_linked__3nC4k:focus {
	background-color: orangered;
}
input.components_linked__3nC4k:not([value='']), input.components_hasLinked__1VvIE:not([value='']) {
	background-color: rgba(255, 68, 0, 0.5);
}

@-webkit-keyframes components_flash-green__2Wczz {
	from {
		background-color: yellow;
	}
	to {
		background-color: darkgreen;
	}
}

@keyframes components_flash-green__2Wczz {
	from {
		background-color: yellow;
	}
	to {
		background-color: darkgreen;
	}
}

.components_reject__3ymMO {
	background-color: darkred;
	border: 2px #252526 solid;
}

.components_reject__3ymMO:focus {
	border: 2px #252526 solid;
}

.components_setResult__ToBDd, .components_info__RdPK8 {
	padding: 5px;
	box-sizing: border-box;
	color: white;
	font-weight: bold;
	-webkit-justify-content: center;
	        justify-content: center;
    text-align: center;
	-webkit-align-items: center;
	        align-items: center;
	background-color: rgb(65, 150, 89);
	border: 2px #1e1e1e solid;
	font-weight: 600;
	border-radius: 5px;
}
.components_info__RdPK8 {
	background-color: rgb(5, 5, 30);
	cursor: pointer;
}

.components_inputURL__3WU4U {
    text-align: left;
}

.components_editableField__2fs18 {
    background-color: rgb(44, 44, 44);
}

.components_centre__2BcEl {
	display: inline-block;
	position: relative;
	height: 100%;
	width: 100%;
	vertical-align: middle;
}

.components_centre__2BcEl:hover span.tooltip {
	opacity: 0.8;
}
.components_detailsContent__2yLbz {
    width: 100%;
    grid-column: 1 / span 3;
	-ms-grid-column-span: 3;
	
	display: grid;
	grid-row-gap: 8px;
	row-gap: 8px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'board'
		'undo'
		'gameLinks';
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.components_row__1jpeO {
	display: grid;
	grid-auto-flow: column;
	white-space: pre-wrap;
	border-radius: 15px;
	overflow: hidden;
	margin: 5px;
}
.components_linkRow__1DIVp {
	grid-template-columns: 15fr 85fr;
}
.components_buttonRow__AIy2p {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	box-sizing: border-box;
    padding: 0 5px;
}
.components_buttonRow__AIy2p > * {
	width: 100%;
}
.components_buttonRow__AIy2p > *:first-child {
	border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.components_buttonRow__AIy2p > *:last-child {
	border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

div.components_topBanner__1k4Eb {
	background-color: #151515;
	width: unset;
}

.components_validationTable__3Yxip {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0 20px;
}
.components_validationTable__3Yxip > div {
	padding: 5px 10px;
}

.components_linkSubmit__TNip6 {
	width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

/* Collapsable Section */

.components_sectionContainer__1tg1I {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	border-radius: 15px;
	margin-bottom: 5px;
}

.components_sectionHeader__-UWwY {
	display: grid;
	grid-template-columns: auto 29px;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	position: relative;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.components_sectionHeader__-UWwY.components_withLengths__2eHLq {
	grid-template-columns: auto 29px 29px;
}

.components_sectionHeaderText__1OTig {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	cursor: pointer;
	padding: 0 10px;
	-webkit-align-items: center;
	        align-items: center;
}

.components_sectionListWrapper__a03uW {
	transition: height 0.5s ease;
	background-color: rgb(38, 38, 38);
}

.components_sectionList__117sb {
	position: relative;
	top: 0%;
}

.components_length__3bnMd {
	color: rgb(150, 150, 150);
	cursor: default;
}

.components_expanded__3qOxR {
	display: inline;
	display: initial;
}

.components_sectionToggle__2z6Il {
	padding: 0 10px;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.components_sectionToggle__2z6Il > * {
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
}

/* TabSection */

.components_tabSection__2c2yE {
	display: grid;
	grid-auto-flow: column;
	align-self: stretch;
	margin: 5px;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	border-radius: 15px;
}

.components_tabSection__2c2yE > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

.components_tabSection__2c2yE > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.components_tab__3HWgq {
    box-sizing: border-box;
	padding: 5px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
}
.components_tab__3HWgq.components_alone__fx8kz {
	cursor: default;
}

.components_tab__3HWgq svg, .components_tab__3HWgq img {
	margin: 0 5px;
	height: 1em;
}

.components_tab__3HWgq :not(svg) {
	overflow: hidden;
}
.components_tab__3HWgq.components_active__1mG2n :not(svg) {
	max-width: 100%;
}
.components_tab__3HWgq:not(.components_active__1mG2n) :not(svg):not(img) {
	max-width: 0px;
}

.components_active__1mG2n {
	background-color: #1e1e1e;
}

.components_tab__3HWgq:not(.components_active__1mG2n) {
	background-color: #424141;
	opacity: 0.6;
	cursor: pointer;
}

div > form > input.components_alwaysVisible__fSqQA {
	visibility: visible!important;
}

div > form > input.components_hasLink__19HOG {
	background-color: orangered;
}

/* Search */

div.components_searchForm__ZWRi0 {
	display: -webkit-flex;
	display: flex;
	z-index: 2;
}
div.components_infoBox__31Uwk {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	-webkit-justify-content: stretch;
	        justify-content: stretch;
	-webkit-align-self: stretch;
	        align-self: stretch;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

div.components_searchBar__34BNl {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	padding: 0 5px;
	height: unset;
	min-height: 32px;
	border-radius: 15px;
}
.components_searchBar__34BNl.components_alwaysHover__23nKx {
	background-color: rgb(69, 69, 69);
}

div.components_searchBar__34BNl > input {
	justify-self: stretch;
	width: 100%;
	background-color: transparent;
	border: none;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding: 5px;
	color: white;
	outline: none;
}

.components_searchResults__tX7x8 {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: stretch;
	        justify-content: stretch;
	text-align: left;
	font-weight: 300;
	font-size: small;
	padding-left: 10px;
	box-sizing: border-box;
}

.components_searchResults__tX7x8 > div {
	padding: 10px 25px;
	cursor: pointer;
	border-radius: 15px;
	box-sizing: border-box;
	height: -webkit-min-content;
	height: -moz-min-content;
	height: min-content;
}
.components_searchResults__tX7x8 > div:focus, .components_searchResults__tX7x8 > div:hover:not(:first-child) {
	background-color: rgba(88, 88, 88, 0.5);
	border: none;
	outline: none;
}
.profile_profileContainer__V5hnJ {
	height: 100%;
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
}
.profile_profileContainer__V5hnJ::-webkit-scrollbar {
	width: 10px;
	background-color: rgba(85, 85, 85, 0.3);
}
.profile_profileContainer__V5hnJ::-webkit-scrollbar-track {
	background-color: transparent;
}
.profile_profileContainer__V5hnJ::-webkit-scrollbar-corner {
	display: none;
}
.profile_profileContainer__V5hnJ::-webkit-scrollbar-button {
	display: none;
}
.profile_profileContainer__V5hnJ::-webkit-resizer {
	display: none;
}
.profile_profileContainer__V5hnJ::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	border-radius: 15px;
}
.profile_profileContainer__V5hnJ::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}
.profile_profileContainer__V5hnJ.profile_skel__fZdvP {
	color: transparent;
}

div.profile_roundRow__3OA2r {
	grid-template-columns: 30% 70%;
}

.profile_row__aUfgI {
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 3;
	-ms-grid-row-span: 3;
	width: 100%;
	white-space: pre-wrap;
	display: grid;
	grid-template-columns: 38.2% auto;
	grid-auto-flow: column;
	grid-column-gap: 2px;
	-webkit-column-gap: 2px;
	        column-gap: 2px;
	-webkit-align-items: stretch;
	        align-items: stretch;
	margin: 2px 0;
	/*transition: max-height 0.5s ease;*/
}

.profile_resultsRow__4zleX {
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 3;
	-ms-grid-row-span: 3;
	width: 100%;
	height: 100%;
	white-space: pre-wrap;
	display: grid;
	grid-template-columns: 10% auto 10% 10%;
	grid-column-gap: 2px;
	-webkit-column-gap: 2px;
	        column-gap: 2px;
	-webkit-align-items: start;
	        align-items: start;
	overflow: hidden;
}

div.profile_toggleKey__1VyZa:hover {
	background-color: #585858;
}

.profile_desc__cZcMt {
	width: 100%;
	font-size: small;
	text-align: left;
	padding: 0 1em;
	word-wrap: break-word;
	box-sizing: border-box;
	transition: max-height ease 0.333s;
}
.profile_li__1aGSj {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}
.profile_desc__cZcMt img {
	max-height: 200px;
}
.profile_desc__cZcMt a:link, .profile_desc__cZcMt a:active, .profile_desc__cZcMt a:visited {
	color: white;
}
.profile_desc__cZcMt .switcher {
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
}

.profile_help__2ocef {
	border-color: transparent transparent rgba(160, 160, 160, 0.5) transparent;
	border-width: 0 0 1.5em 2.5em;
	cursor: pointer;
}
.profile_help__2ocef > * {
	position: absolute;
	top: 0.6em;
	right: 0em;
	height: 0.8em;
	opacity: 0.8;
}

#poolButton {
	margin: 1em 0;
	height: 20px;
	background-color: darkblue;
	cursor: pointer;
	border-radius: 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.profile_bonusRow__1sVIm {
    justify-items: stretch;
	grid-template-columns: auto repeat(2, 1fr) 15% 15%;
	grid-template-areas:
		'round MP GP edit submit';
}
.profile_bonusRow__1sVIm:not(.profile_matchOnly__q_hNo) div:first-child {
	padding-right: 16px;
}
.profile_bonusRow__1sVIm.profile_matchOnly__q_hNo {
	grid-template-areas:
		'round round MP edit submit';
}

div.profile_editable__24Eas, div.profile_editableField__2onPe {
	background-color: #4b4b4b;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
div.profile_editable__24Eas.profile_false__2sgus {
	background-color: indianred;
}
div.profile_editable__24Eas.profile_true__2_Pld {
	background-color: darkolivegreen;
}

div.profile_editableField__2onPe, input.profile_input__2tu2X {
	font-size: 13.3333px;
	text-align: left;
}

input.profile_input__2tu2X, div.profile_editable__24Eas:hover, div.profile_editableField__2onPe:hover {	
	background-color: #8b8b8b;
}

.profile_sectionListWrapper__1K1-l {/*
	overflow: hidden;
	transform: scaleY(-1);*/
	transition: height 0.5s ease;
}
/*
.sectionListWrapper > * {
	transform: scaleY(-1);
}*/

.profile_sectionList__3bJ8Z {
	position: relative;
	top: 0%;
}

.profile_expanded__3QtaO {
	display: inline;
	display: initial;
}

.profile_sectionToggle__2qBPk {
	padding: 0 10px;
    cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.profile_sectionToggle__2qBPk > * {
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
}

div.profile_semiPresent__1APEU {
	background: repeating-linear-gradient(
		45deg,
		rgb(33, 56, 56),
		rgb(33, 56, 56), 10px,
		rgb(33, 33, 33) 10px,
		rgb(33, 33, 33) 20px
	);
}

div.profile_playerLine__IVVus {
    grid-template-columns: 85% 15%;
}

input.profile_input__2tu2X {
    width: 85%;
}

button.profile_cancel__AKFEe {
    grid-column: 2 / span 1;
	-ms-grid-column-span: 1;
}

.profile_profilePictureFrame__3cF5B {
	grid-area: profile;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.profile_profilePicture__d0y9A {
	width: 100%;
	max-width: 150px;
}

.profile_infoSection__1V7wB {
	border-radius: 15px;
	overflow: hidden;
}

div.profile_close__1jnoP {
	background-color: indianred;
}
div.profile_closeButton__3lSRr {
	padding: 5px;
	height: unset;
	border-radius: 15px;
	margin: 5px;
}

div.profile_closeButton__3lSRr > svg {
	justify-self: left;
}

div.profile_name__bRkcf {
	font-weight: bold;
	padding: unset;
	grid-column: 1 / -1;
}

.profile_scoreSection__3GgY5 {
	width: 100%;
	display: grid;
	grid-column-gap: 2px;
	-webkit-column-gap: 2px;
	        column-gap: 2px;
	grid-template-columns: 30.8% repeat(3, 1fr);
	grid-template-areas:
		'round MP GP submit'
}
.profile_scoreSection__3GgY5.profile_matchOnly__q_hNo {
	grid-template-areas:
		'round round MP submit'
}

.profile_scoreSection__3GgY5 > input {
	width: unset;
	overflow: hidden;
}
.profile_scoreSection__3GgY5 > button {
	width: 100%;
}

.profile_tieBreaks__101TV {
	border-radius: 15px;
	display: grid;
	grid-auto-flow: row;
	grid-row-gap: 2px;
	row-gap: 2px;
	padding: 2px;
}

div.profile_divider__2y1J3 {
	min-height: 1em;
	background-color: rgb(33, 56, 56);
	background: repeating-linear-gradient(45deg,
		rgb(22, 45, 45),
		rgb(22, 45, 45), 10px,
		rgb(33, 56, 56) 10px,
		rgb(33, 56, 56) 20px
	);
	grid-column: span 2;
	-webkit-justify-content: center;
	        justify-content: center;
}
.profile_tb__2hp89:hover {
	background-color: rgb(88, 88, 88);
}

.profile_baseContainer__k1otM {
	display: grid;
	grid-template-columns: 150px auto;
	grid-template-areas:
		'profile description';
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	grid-row-gap: 5px;
	row-gap: 5px;
	min-height: 150px;
}
.profile_description__U2kC4 {
	display: grid;
	grid-area: description;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	grid-template-areas:
		'textarea textarea'
		'reset submit';
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	grid-row-gap: 5px;
	row-gap: 5px;
	width: 100%;
}
div.profile_descriptionDiv__1qv0R {
	white-space: unset;
	-webkit-align-items: start;
	        align-items: start;
	-webkit-justify-content: start;
	        justify-content: start;
}
.profile_description__U2kC4 textarea {
	grid-area: textarea;
	border: 1px grey solid;
	border-radius: 5px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	text-align: left;
	font-size: 13.333px;
}
.profile_description__U2kC4 button.reset {
	grid-area: reset;
}
.profile_description__U2kC4 button.submit {
	grid-area: submit;
}

.profile_bottomBanner__b2MfX {
	grid-area: SettingsBar;
	position: relative;
	-webkit-animation: profile_slide-in-from-right__2jdIT 1s ease-out;
	        animation: profile_slide-in-from-right__2jdIT 1s ease-out;
}

@-webkit-keyframes profile_slide-in-from-right__2jdIT {
	from {
		right: -100%;
	}
	to {
		right: 0%;
	}
}

@keyframes profile_slide-in-from-right__2jdIT {
	from {
		right: -100%;
	}
	to {
		right: 0%;
	}
}

.profile_fsStage__2bN6- {
	grid-column: 1 / -1;
	display: grid;
	grid-template-rows: auto 1fr;
	width: 100%;
	height: 100%;
	grid-row: 1 / -1;
	padding: '0 20px';
}

div.profile_fs__18DGe {
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}
div.profile_fs__18DGe > * {
	white-space: nowrap;
	word-break: keep-all;
}
div.profile_fs__18DGe .fieldBox {
	overflow-x: unset;
}
div.profile_fs__18DGe > div[class^='components_sectionContainer'] {
	max-width: 38.1vw;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}
.dock_dock__2-6mz {
	grid-area: Dock;
	width: 100%;
	height: 100%;
	padding: 5px;
	box-sizing: border-box;
	left: 0%;
	position: relative;
	-webkit-animation: dock_slide-in-from-left__LnSmo 1s ease-out both;
	        animation: dock_slide-in-from-left__LnSmo 1s ease-out both;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
}
@-webkit-keyframes dock_slide-in-from-left__LnSmo {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}
@keyframes dock_slide-in-from-left__LnSmo {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

.dock_stage__jkkt8 {
	background-color: rgba(45, 45, 45);
	width: 100%;
	height: 100%;
	border-radius: 15px;
	padding: 8px 5px;
	box-sizing: border-box;
	overflow: visible!important;
	overflow-y: auto;
}

.dock_column__1TLFS {
	display: grid;
	grid-row-gap: 2px;
	row-gap: 2px;
	height: 100%;
}

.dock_column__1TLFS > *:first-of-type .dock_button__2E4Pt {
	padding-top: 10px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.dock_column__1TLFS > *:last-of-type .dock_button__2E4Pt {
	padding-bottom: 10px;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.dock_container__2lJ_b {
	position: relative;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.dock_container__2lJ_b > svg {
	color: darkslategray;
}
.dock_container__2lJ_b .dock_button__2E4Pt {
	width: 100%;
	padding: 1.1vh 0;
	font-size: 2.2vh;
	background: transition 1s ease;
}
.dock_container__2lJ_b.dock_active__26ORc .dock_button__2E4Pt {
	background: repeating-linear-gradient(
		45deg,
		rgba(33, 120, 33, 0.25),
		rgba(33, 120, 33, 0.25), 10px,
		rgba(33, 33, 33, 0.25) 10px,
		rgba(33, 33, 33, 0.25) 20px
	);
}
.dock_container__2lJ_b.dock_active__26ORc::before {
	height: 100%;
	width: 2px;
	background-color: gold;
	content: ' ';
	position: absolute;
	left: 0px;
}
.dock_name__3nars, .dock_options__3ib8q {
	position: absolute;
	left: calc(100% + 15px);
	font-size: 14px;
	z-index: 3;
	padding: 10px;
	box-shadow: 1px 1px 2px rgb(15, 15, 15);
	opacity: 0.9;
	transition: opacity 0.1s ease;
}
.dock_name__3nars {
	font-size: 14px;
	white-space: nowrap;
	display: -webkit-flex;
	display: flex;
	background-color: rgb(15, 15, 25);
	padding: 10px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	pointer-events: none;
}
.dock_options__3ib8q {
	display: grid;
	grid-row-gap: 6px;
	row-gap: 6px;
	background-color: rgb(45, 24, 15);
	border-radius: 5px;
	min-width: 15ch;
	opacity: 0.9;
	transition: opacity 0.1s ease;
	transition: display 0.1s ease 0.1s;
	z-index: 1;
	font-size: large;
}
.dock_options__3ib8q:not(.dock_visible__1Gsu6) {
	opacity: 0;
	display: none;
}
.dock_options__3ib8q > div {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 8px;
	-webkit-column-gap: 8px;
	        column-gap: 8px;
	padding: 0 8px;
	-webkit-justify-content: left;
	        justify-content: left;
	text-align: left;
}
.dock_optionsTitle___Xl28 {
	font-weight: bold;
	padding: 5px;
}
.dock_container__2lJ_b:not(:hover) .dock_name__3nars, .dock_container__2lJ_b.dock_active__26ORc .dock_name__3nars {
	opacity: 0;
}

.dock_sort__QH6Ad {
	min-height: 80vh;
	margin-top: -60px;
}
.inputs_blank__2jmg4, .inputs_check__3A60n, .inputs_ban__3NkCY {
	cursor: pointer;
	pointer-events: all;
}

.inputs_blank__2jmg4 {
	color: rgb(15, 15, 15);
}

.inputs_check__3A60n {
	color: lightgreen;
}

.inputs_ban__3NkCY {
	color: indianred;
}
/* Depth: 1  */

.core_stage__Wa7eX {
	grid-column: 1 / -1;
	grid-row: Players / PlayerBar;
	display: grid;
	grid-template-rows: auto 1fr;
	grid-row-gap: 5px;
	row-gap: 5px;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
}
.core_stage__Wa7eX.core_short__EG69G {
	grid-column: 1 / -2;
	overflow-x: auto;
	overflow-y: auto;
}
.core_stage__Wa7eX a {
	color: unset;
}

/* Depth: 2 */

.core_titleContainer__1y9dK {
	border-radius: 15px;
    width: 100%;
    padding: 5px;
	box-sizing: border-box;
}
.core_titleWrapper__2Ec1n {
	background-color: rgba(45, 45, 45);
    border-radius: 15px;
}

.core_container__1ak0c {
	height: 100%;
	background-color: #2d2d2d;
    border-radius: 15px;
}

/* Depth: 3 */

.core_titles__3TVw1 {
	display: grid;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	justify-items: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	padding: 5px;
	border-radius: 15px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	max-width: 100%;
}

.core_title__3YmoD {
	font-weight: bold;
	padding: 5px 15px;
}

.core_description__29Jn8 {
	font-weight: 300;
	font-size: small;
	max-width: 50vw;
	text-align: left;
}

/* Depth: 3 */

.core_titles__3TVw1 .button {
	background-color: #1e1e1e;
	border-radius: 15px;
	padding: 8px 16px;
	height: 100%;
	font-size: 14px;
	text-decoration: none;
	box-sizing: border-box;
}
/* Depth: 1 */

.dropdown_container__3Irea {
	position: relative;
	z-index: 2;
}

/* Depth: 2 */

.dropdown_header__1bzxL {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	position: relative;
	border-radius: 15px;
}
div.dropdown_header__1bzxL:hover:not(.dropdown_sent__129Rn) {	
	color: var(--col-accent1-hover);
}


.dropdown_wrapper__2ihat {
	position: absolute;
	overflow: hidden;
	width: 100%;
	transition: height 0.33s ease;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: 1;
}

/* Depth: 3 */

.dropdown_list__3-k52 {
	margin: 2px 0 0 0;
	padding: 0px;
	width: 100%;
	display: grid;
	grid-row-gap: 1px;
	row-gap: 1px;
	background-color: rgb(69, 69, 69)
}

.dropdown_list__3-k52 li {
	list-style: none;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	background-color: #1e1e1e;
	padding: 5px;
	box-sizing: border-box;
	cursor: pointer;
	font-size: small;
}

.dropdown_list__3-k52 li:hover {
	background-color: rgb(69, 69, 69)
}
/* Depth: 1 */

.sort_stage__32jJR {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	height: 100%;
	overflow: hidden;
	padding-bottom: 10px;
	box-sizing: border-box;
}

/* Depth: 2 */

.sort_description__DDBWU {
	margin-bottom: 10px;
	font-size: small;
}

.sort_col1__-ynyz, .sort_col2__9U2t_, .sort_col3__2Afyx {
	display: grid;
	height: 100%;
	overflow: hidden;
	grid-template-rows: auto 1fr;
	grid-row-gap: 10px;
	row-gap: 10px;
}

/* Depth: 3.1 */

.sort_row__31Q6V, .sort_title__3Cp_F {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-auto-flow: column;
	grid-column-gap: 7px;
	-webkit-column-gap: 7px;
	        column-gap: 7px;
	min-height: 32px;
}
div.sort_header__1qU6j {
	transition: grid-template-columns 0.5s ease;
	transition: grid-template-columns 0.5s ease, -ms-grid-columns 0.5s ease;
	height: 100%;
    background-color: rgb(69, 69, 69);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
div.sort_row__31Q6V.sort_expanded__1zIdC {
	grid-template-columns: 50px 1fr;
}
div.sort_row__31Q6V.sort_expanded__1zIdC .sort_header__1qU6j {
	font-size: 0px;
}
div.sort_row__31Q6V.sort_expanded__1zIdC .sort_header__1qU6j * {
	pointer-events: none;
}
div.sort_searchButton__3t3w8 {
	padding: 0px;
}

/* Depth: 3.2 */

.sort_templates__3RmWu {
	display: grid;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: auto;
	padding-right: 10px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 10px;
	row-gap: 10px;
	box-sizing: border-box;
}

.sort_templates__3RmWu > div {
	height: 190px;
	background-color: rgba(40, 40, 75, 0.5);
	border-radius: 15px;
	padding: 10px;
	display: grid;
	grid-template-rows: 150px 1fr;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: center;
}

.sort_unrepresented__3l1vM {
	opacity: 0.5;
}
.sort_large__-G2LD {
	font-size: x-large;
	grid-template-rows: 150px;
}
.sort_selected__3lITO.button {
    background-color: rgb(60, 60, 100);
}
.sort_templates__3RmWu > div > img {
	height: 80px;
	border-radius: 5px;
	justify-self: center;
}
.sort_templates__3RmWu img[data-custom-flag] {
	-webkit-transform: scaleX(0.66);
	        transform: scaleX(0.66);
}

.sort_clear__3ndHS {
	grid-column: 1 / span 1;
}

/* Depth: 3.3 */

.sort_listWrapper__2jzYH {
	height: 100%;
}
.sort_list__X78Nr {
	display: grid;
	grid-auto-rows: auto;
	grid-row-gap: 5px;
	row-gap: 5px;
	padding: 5px;
	box-sizing: border-box;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

div.sort_player__PrjDw {
	border-radius: 5px;
	min-height: 2em;
	height: unset;
	z-index: 1;
}

.sort_title__3Cp_F {
	grid-template-columns: 1fr auto auto;
}


/* Draggable */

div.draggable_spacer__3RZCV {
	width: 100%;
	min-height: 60px;
	-webkit-animation: draggable_fade-in__18Frg 1s ease 1s;
	        animation: draggable_fade-in__18Frg 1s ease 1s;
}
.draggable_spacer__3RZCV.draggable_highlighted__MQkbn {
	display: -webkit-flex;
	display: flex;
	position: relative;
	padding: 5px;
	box-sizing: border-box;
}
.draggable_spacer__3RZCV.draggable_highlighted__MQkbn::after {
	position: absolute;
	border: 1.5px dashed rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
	margin: 5px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(50, 50, 100, 0.3);
	border-radius: 5px;
	content: '+';
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
@-webkit-keyframes draggable_fade-in__18Frg {
	from {
		opacity: 0%
	}
	to {
		opacity: 100%;
	}
}
@keyframes draggable_fade-in__18Frg {
	from {
		opacity: 0%
	}
	to {
		opacity: 100%;
	}
}

/* Depth: 2 */

div.draggable_listElement__h1q4X {
	display: grid;
	grid-column-gap: 2px;
	-webkit-column-gap: 2px;
	        column-gap: 2px;
	grid-template-columns: 1fr;
	grid-auto-flow: column;
}
div.draggable_listElement__h1q4X.draggable_columns__2tdtx {
	grid-template-columns: auto 1fr;
}

div.draggable_listElement__h1q4X, div.draggable_buttonContainer__1RgHU {
	transition: margin-top 0.02s ease;
}

div.draggable_number__2D2V0 {
	background-color: #1e1e1e;
}
.draggable_number__2D2V0.draggable_ignored__33WO4 {
	color: transparent;
}

div.draggable_buttonContainer__1RgHU {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 2px;
	-webkit-column-gap: 2px;
	        column-gap: 2px;
	overflow: hidden;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

div.draggable_buttonContainer__1RgHU > div {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	padding: 5px;
	-webkit-justify-content: center;
	        justify-content: center;
	cursor: pointer;
}

div.draggable_buttonContainer__1RgHU > div > svg {
	padding: 5px;
}
div.draggable_buttonContainer__1RgHU > div > img {
	height: 1em;
}
div.draggable_tbReset__Q1n8C {
	background-color: rgb(0, 52, 156);
}
div.draggable_tbSubmit__2qF0F {
	background-color: darkgreen;
}
/* Depth: 1 */

.input_body__1b-ez {
	display: grid;
	grid-template-rows: auto 1fr minmax(30vh, auto);
}

/* Paste */
.input_stage__2pwOH {
	grid-column: 1 / -2;
}

.input_stage__2pwOH > div.container {
	grid-column: 1 / span 2;
	-ms-grid-column-span: 2;
	height: 100%;
}

.input_table__GZnpT {
	grid-row-start: 2;
	grid-row-end: -1;
}

.input_headerSection__2Ig4V, .input_errors__2Tcf9 {
	min-height: 40vh;
}
.input_headerSection__2Ig4V {
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}
.input_errors__2Tcf9 {
	grid-row: 2 / span 1;
	-ms-grid-row-span: 1;
}
.input_errors__2Tcf9 .input_list__3gHf3 {
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	grid-template-columns: auto 50px;
}
.input_errors__2Tcf9 .input_list__3gHf3 .button {
	cursor: pointer;
}
.input_error__35vPN:hover {
	background-color: indianred;
}

.input_pasteContainer__GiXDL {
	background-color: #2d2d2d;
	border-radius: 15px;
	height: 100%;
	overflow-x: scroll;
}
.input_paste__2hyfv {
	width: 100%;
	box-sizing: border-box;
	padding: 5px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.input_subheader__cNlLV {
	border-radius: 15px;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 15px;
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	-webkit-align-items: center;
	        align-items: center;
}
.input_subheader__cNlLV > * {
	box-sizing: border-box;
}
.input_pasteArea__1SDj2 {
	border-radius: 15px;
	overflow: hidden;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
}

.input_form__2v-ub {
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 90% 10%;
}

.input_textarea__1FWhO, .input_output__VuryB {
	border-radius: 15px;
	max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(33, 33, 33);
    height: 100px;
	color: white;
    position: relative;
	padding: 5px;
	resize: vertical;
}

button.input_submit__12haZ, div.input_submit__12haZ {
	padding: 10px 5px;
	height: unset;
	border-radius: 15px;
	border: none;
	color: white;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	background-color: green;
}

button.input_reset__3CJ7t {
	background-color: rgb(0, 0, 49);
	margin: 5px 0;
}

button.input_clear__dg7Lv {
	background-color: darkred;
	margin: 5px 0;
}

.input_playerTable__1E0SY {
	width: 100%;
}

div.input_parsedHeader__3UNYl {
	background-color: gold;
	color: black;
	border-radius: 5px;
	min-height: 2em;
	height: unset;
	z-index: 1;
}

.input_list__3gHf3 {
	display: grid;
	grid-auto-rows: 1fr;
	grid-row-gap: 5px;
	row-gap: 5px;
	padding: 5px;
}

.input_tableHeader__2R4Rf {
	min-height: 2em;
	transition: background-color 0.25s ease;
}
.input_tableHeader__2R4Rf.input_dragEnter__197KT {
	background-color: blue;
}

.input_target__GK4nW {
	height: 100%;
	position: relative;
}

.input_placeholder__vx5TQ {
	text-align: justify;
	padding: 20px;
	opacity: 0.4;
	font-weight: 300;
	pointer-events: none;
	max-height: 100%;
	overflow: hidden;
	font-family: monospace;
}

div.input_error__35vPN {
	cursor: pointer;
	box-sizing: border-box;
}

div.input_errorCell__3yQ4_ {
	background-color: rgb(129, 25, 25);
}

div.input_highlighted__2WnFW {
	background-color: darkred;
}

div.input_description__1s735 {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-weight: 500;
	font-size: 4vh;
}
/* Depth: 1 */
.announcements_stage__ytg8g {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.announcements_stage__ytg8g a {
	color: unset;
}

/* Depth: 2 */
.announcements_col1__1ernB {
	display: grid;
	grid-template-rows: 1fr auto;
}
.announcements_col2__2Ikrd {
	height: 100%;
	display: grid;
	grid-auto-rows: auto;
}

/* Depth: 3 */

.announcements_stage__ytg8g > div.container {
	height: 100%;
	background-color: #2d2d2d;
    border-radius: 15px;
}

.announcements_editingArea__1Jgpe {
	grid-row: 1 / span 1;
	background-color: #2d2d2d;
    border-radius: 15px;
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	grid-row-gap: 10px;
	row-gap: 10px;
}

.announcements_textarea__3jf0L {
	border-radius: 15px;
    max-width: 100%;
    width: 100%;
    background-color: #212121;
    height: 100%;
    color: #fff;
    position: relative;
	padding: 10px;
    box-sizing: border-box;
	-webkit-user-select: auto;
	    -ms-user-select: auto;
	        user-select: auto;
	outline: none;
}

.announcements_preview__1Bqru {
	text-align: justify;
	display: grid;
	grid-template-rows: auto 1fr;
}
.announcements_previewWrapper__nQIwu {
	font-family: Josefin Sans, sans-serif;
	word-wrap: break-word;
	padding: 10px;
	box-sizing: border-box;
	font-weight: 300;
}

.announcements_buttons__1hFWn {
	grid-row: 2 / span 1;
	min-height: 1.5em;
}

.announcements_buttons__1hFWn > div {
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	border-radius: 15px;
	overflow: hidden;
}

button.announcements_submit__2wmTb, div.announcements_submit__2wmTb {
	padding: 10px 5px;
	height: unset;
	border: none;
	color: white;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	background-color: green;
}

button.announcements_reset__QjNmB {
	background-color: rgb(0, 0, 49);
}

button.announcements_clear__1UpCl {
	background-color: darkred;
}


/* Depth: 2 */

.crosstable_wrapper__JHCOc {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.crosstable_table__3zvgj {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.crosstable_td__1sGEm, .crosstable_th__10uL3 {
	padding: 0;
}

.crosstable_cell__1MJn3 {
	display: -webkit-flex;
	display: flex;
	-webkit-align-content: center;
	        align-content: center;
	background-color: #1e1e1e;
	border: 1px #252526 solid;
	padding: 8px 15px 8px 15px;
	white-space: nowrap;
	font-size: 13.3333px;
	cursor: default;
	-webkit-justify-content: center;
	        justify-content: center;
}

.crosstable_blank__3PQt4 {
	background-color: transparent;
	border: none;
}

.crosstable_player__2j1uI:hover {
	background-color: #3c3c3c;
}

.crosstable_edit__1bPyk {
	background-color: rgb(33, 56, 56);
}

div.crosstable_headerCell__3uT06, div.crosstable_firstColumn__pA3Ha {
	font-weight: bold;
	background-color: transparent;
	border: none;
}

div.crosstable_closeButton__21AWF {
	margin-left: 30px;
	justify-self: flex-end;
}
a.crosstable_closeButton__21AWF {
	margin-left: 30px;
	justify-self: flex-end;
}

.crosstable_container__3OxZH {
    grid-column: 1 / span 2;
	-ms-grid-column-span: 2;
    margin: 30px;
    min-height: 70vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: calc(100% - 60px);
}

.crosstable_submit__3hLUD {
    width: 100%;
}

/* SCROLLBAR */

.crosstable_scrollbar__2K4Bx {
	position: absolute;
	width: 20px;
	z-index: 1;
	background-color: white;
}

.crosstable_track__1kz8g {
	z-index: 3;
	width: 100%;
	background-color: black;
	height: 40px;
}
/* Depth: 1 */
.spreadsheet_core__2Hqdx {
	grid-column: unset;
	grid-row: unset;
}
.spreadsheet_core__2Hqdx div.button#decrement {
	margin-left: 20px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.spreadsheet_core__2Hqdx div.spreadsheet_indicator__2oq00.button {
	border-radius: 0px;
	cursor: default;
	background-color: #1e1e1e;
}
.spreadsheet_core__2Hqdx div.button#increment {
	margin-right: 20px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

/* Depth 1.1 */

.spreadsheet_stage__1TffK {
	width: 100%;
	height: 100%;
	overflow: scroll;
	position: relative;
}

/* Depth: 2 */
.spreadsheet_players__1NUu8 {
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
}
.spreadsheet_players__1NUu8 textarea {
	height: 100%;
	width: 100%;
	word-wrap: none;
}

/* Table Styles */
.spreadsheet_table__2yVXp {
	border: none;
}
.spreadsheet_table__2yVXp .spreadsheet_header__3iIri:first-child {
	border-top-left-radius: 20px;
}
.spreadsheet_table__2yVXp .spreadsheet_header__3iIri:last-child {
	border-top-right-radius: 20px;
}
.spreadsheet_table__2yVXp tr:last-child .cell:first-child {
	border-bottom-left-radius: 20px;
}
.spreadsheet_table__2yVXp tr:last-child .cell:last-child {
	border-bottom-right-radius: 20px;
}
.spreadsheet_table__2yVXp tr:last-child .cell span {
	padding-bottom: 5px;
}
.spreadsheet_players__1NUu8 .spreadsheet_table__2yVXp.data-grid .cell {
	padding: 5px;
	box-sizing: border-box;
	border: none;
	max-width: 250px;
	word-wrap: break-word;
}
.spreadsheet_players__1NUu8 .spreadsheet_table__2yVXp.data-grid .spreadsheet_header__3iIri.cell.read-only {
	font-weight: bold;
	background-color: rgba(245, 245, 245, 0.05);
	color: white;
	text-align: right;
	padding: 5px 10px;
	-webkit-user-select: text;
	    -ms-user-select: text;
	        user-select: text;
}
.spreadsheet_header__3iIri[data-locked] span::after {
	padding: 0 5px;
	content: '🛇';
	opacity: 0.3;
}
.spreadsheet_players__1NUu8 .spreadsheet_table__2yVXp.data-grid .cell.read-only {
	background-color: rgba(0, 0, 0, 0.3);
	color: rgb(130, 130, 130);
	cursor: default;
	text-align: right;
}
.spreadsheet_players__1NUu8 .spreadsheet_table__2yVXp.data-grid .cell.read-only.selected {
	box-shadow: none;
}
.spreadsheet_players__1NUu8 .spreadsheet_table__2yVXp.data-grid .cell.selected.editing {
	cursor: text;
}
.spreadsheet_cell__2AdAn[data-header="id"] span {
	white-space: pre;
}
.spreadsheet_table__2yVXp tr {
	position: relative;
	overflow: hidden;
}
.spreadsheet_table__2yVXp tr:nth-child(2n) {
	background-color: rgba(120, 0, 0, 0.2);
}
.spreadsheet_table__2yVXp tr:nth-child(2n - 1) {
	background-color: rgba(0, 0, 120, 0.3);
}

.spreadsheet_table__2yVXp.data-grid .cell.editing input {
	color: white;
	border: none;
	outline: none;
	font-size: unset;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.spreadsheet_longSelect__vl3OC {
	visibility: visible;
}
.spreadsheet_players__1NUu8 .spreadsheet_table__2yVXp tr.spreadsheet_longSelect__vl3OC td:first-child {
	box-shadow: -3px 0px 0px 0px red;
}
.spreadsheet_players__1NUu8 .spreadsheet_table__2yVXp tr:first-child td.spreadsheet_longSelect__vl3OC {
	box-shadow: 0px -3px 0px 0px red;
}

/* Read-only settings */

.spreadsheet_players__1NUu8.spreadsheet_editOnly__WSFfV .cell.read-only:not(.spreadsheet_header__3iIri), .spreadsheet_players__1NUu8.spreadsheet_editOnly__WSFfV .spreadsheet_header__3iIri[data-locked] {
	display: none;
}

/* Depth: 3 */
.spreadsheet_column__3oIsX {
	display: grid;
	grid-row-gap: 20px;
	row-gap: 20px;
	height: 100%;
}
/* Depth: 1 */

.export_core__SWaFn {
	background-color: rgba(0, 0, 0, 0.9);
}

.export_wrapper__1CBOE {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-template-areas:
		'spreadsheet fide'
		'spreadsheet ecf';
}

/* Depth: 2 */

.export_spreadsheet__2XIHq {
	grid-area: spreadsheet;
	-webkit-animation: export_slide-in-from-left__27Yjk 1s ease;
	        animation: export_slide-in-from-left__27Yjk 1s ease;
}

.export_ecf__3lhfN {
	grid-area: ecf;
	-webkit-animation: export_slide-in-from-right__1RXTC 1s ease;
	        animation: export_slide-in-from-right__1RXTC 1s ease;
}
.export_fide__3F8h- {
	grid-area: fide;
	-webkit-animation: export_slide-in-from-right__1RXTC 1s ease;
	        animation: export_slide-in-from-right__1RXTC 1s ease;
}

.export_core__SWaFn.export_sole__2daWg div.export_spreadsheet__2XIHq:not(.export_expanded__1dbnx),
.export_core__SWaFn.export_sole__2daWg div.export_ecf__3lhfN:not(.export_expanded__1dbnx),
.export_core__SWaFn.export_sole__2daWg div.export_fide__3F8h-:not(.export_expanded__1dbnx) {
	display: none;
}
.export_wrapper__1CBOE > div.export_expanded__1dbnx {
	grid-area: unset;
	grid-column: 1 / -1;
	grid-row: 1 / -1;
}


/* Depth 3.2 */

.export_container__2_isA {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
}
.export_textarea__2fcOU {
	height: 100%;
	width: 100%;
	background-color: unset;
	color: white;
	box-sizing: border-box;
	padding: 5px;
	-webkit-user-select: all;
	    -ms-user-select: all;
	        user-select: all;
	white-space: pre;
	border: none;
}

/* Animations */

@-webkit-keyframes export_slide-in-from-right__1RXTC {
	from {
		position: relative;
		right: -100%;
	}
	to {
		position: relative;
		right: 0%;
	}
}

@keyframes export_slide-in-from-right__1RXTC {
	from {
		position: relative;
		right: -100%;
	}
	to {
		position: relative;
		right: 0%;
	}
}

@-webkit-keyframes export_slide-in-from-left__27Yjk {
	from {
		position: relative;
		left: -100%;
	}
	to {
		position: relative;
		left: 0%;
	}
}

@keyframes export_slide-in-from-left__27Yjk {
	from {
		position: relative;
		left: -100%;
	}
	to {
		position: relative;
		left: 0%;
	}
}
/* Depth: 1 */
.knockout_stage__3_Nwn {
	width: 100%;
	height: 100%;
	position: relative;
}

/* Depth: 2 */
.knockout_columns__G4ps0 {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	grid-column-gap: 60px;
	-webkit-column-gap: 60px;
	        column-gap: 60px;
	-webkit-align-items: center;
	        align-items: center;
	margin: 20px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

/* Depth: 3 */
.knockout_column__3FzXJ {
	display: grid;
	grid-row-gap: 20px;
	row-gap: 20px;
	height: 100%;
}

/* Depth: 4 */
.knockout_matchUp__1s3Dx {
	display: grid;
	grid-row-gap: 2px;
	row-gap: 2px;
	grid-template-rows: 1fr 1fr;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	background-color: #2a2a2a;
	border-radius: 15px;
	padding: 5px;
	position: relative;
}

/* Depth: 5 */
.knockout_white__1Uqd0, .knockout_black__3Xr8o {
	display: grid;
	grid-template-columns: auto 1fr auto;
	-webkit-align-items: center;
	        align-items: center;
	background-color: #1e1e1e;
	border-radius: 15px;
	padding: 0 8px;
	box-sizing: border-box;
	position: relative;
}
.knockout_white__1Uqd0 svg, .knockout_black__3Xr8o svg {
	font-size: x-small;
}

/* Depth: 6 */
div.knockout_playerInfo__1N2wU {
	text-align: left;
	-webkit-align-items: center;
	        align-items: center;
	box-sizing: border-box;
	padding-right: 45px;
	white-space: nowrap;
}
div.knockout_playerInfo__1N2wU:hover {
	background-color: unset;
}

.knockout_matchUp__1s3Dx div[class^=components_resultsBridge] {
	width: unset;
	align-self: center;
	grid-auto-flow: row;
	position: absolute;
	right: 1.5ch;
	z-index: 3;
	font-size: small;
	grid-row-gap: 10%;
	row-gap: 10%;
}
.knockout_matchUp__1s3Dx div[class^=components_resultsBridge]:hover {
	background-color: unset;
}
.knockout_matchUp__1s3Dx div[class^=components_neutral], .knockout_playerInfo__1N2wU div[class^=components_centre] {
	display: none;
}
.knockout_matchUp__1s3Dx div[class^=components_neutral], .knockout_playerInfo__1N2wU div[class^=components_centre] {
	display: none;
}

/* Lines */

.knockout_winner__2sdFe, .knockout_all__1hHqo {
	width: 5px;
	height: 5px; 
	border-radius: 50%;
	background-color: gold;
	content: ' ';
	z-index: 1;
	position: absolute;
}
.knockout_all__1hHqo {
	left: -1ch;
}
.knockout_winner__2sdFe {
	right: -1ch;
}

.knockout_connectors__Vozd4 {
	position: absolute;
	top: 0px;
	left: 0px;
}

.knockout_line__2pe3t {
	stroke-width: 2px;
	stroke: gold;
	fill: transparent;
}
.pairings_pairings__1W2ah {
	grid-area: Pairings;
	height: 100%;
	width: 100%;
	-webkit-animation: pairings_fade-in__3evHz 2s ease-out;
	        animation: pairings_fade-in__3evHz 2s ease-out;
	padding: 5px;
	box-sizing: border-box;
	display: grid;
}
.pairings_switch__Eycei {
	-webkit-align-items: start;
	        align-items: start;
}

.pairings_stage__2RkOa {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	overflow: scroll;
	border-radius: 15px;
	background-color: rgb(45, 45, 45);
	-webkit-animation: pairings_fade-in__3evHz 1s ease-out;
	        animation: pairings_fade-in__3evHz 1s ease-out;
}
.pairings_knockoutWrapper__3hP6O {
	padding: 10px;
	box-sizing: border-box;
	overflow-y: auto;
	display: grid;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.pairings_pool__3ObdH {
	padding: 10px 0;
}

.pairings_pairingContainer__2wR3I {
    height: 100%;
}
.pairings_pairingContainer__2wR3I div.button, .pairings_pairingContainer__2wR3I button.button {
	margin: 10px 5px;
}
.pairings_pairingContainer__2wR3I::-webkit-scrollbar {
	width: 10px;
	background-color: rgba(85, 85, 85, 0.3);
}
.pairings_pairingContainer__2wR3I::-webkit-scrollbar-track {
	background-color: transparent;
}
.pairings_pairingContainer__2wR3I::-webkit-scrollbar-corner {
	display: none;
}
.pairings_pairingContainer__2wR3I::-webkit-scrollbar-button {
	display: none;
}
.pairings_pairingContainer__2wR3I::-webkit-resizer {
	display: none;
}
.pairings_pairingContainer__2wR3I::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	-webkit-transition: background-color 2s ease;
	transition: background-color 2s ease;
	border-radius: 15px;
}
.pairings_pairingContainer__2wR3I::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}
.pairings_pairingContainer__2wR3I input {
	outline: none;
}

.pairings_pairing__1SO-O {
	display: grid;
	grid-template-columns: 3fr 135px 3fr;
	justify-items: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-animation: pairings_fade-in__3evHz 1s ease-out;
	        animation: pairings_fade-in__3evHz 1s ease-out;
	justify-self: stretch;
	box-sizing: border-box;
	border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
	font-size: 13.3333px;
}
.pairings_pairing__1SO-O.tempHighlight {
	-webkit-animation: pairings_set-blue-background__2lvwW 5s ease;
	        animation: pairings_set-blue-background__2lvwW 5s ease;
}
@-webkit-keyframes pairings_set-blue-background__2lvwW {
	from {
		background-color: lightblue;
	}
	to {
		background-color: unset;
	}
}
@keyframes pairings_set-blue-background__2lvwW {
	from {
		background-color: lightblue;
	}
	to {
		background-color: unset;
	}
}
.pairings_pairing__1SO-O > div:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.pairings_pairing__1SO-O > div:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.pairings_pairing__1SO-O:hover .pairings_playerInfo__2uaDY, .pairings_pairing__1SO-O:hover > form {
	background-color: #585858;
}
.pairings_pairing__1SO-O:not(:hover) input {
	visibility: hidden;
}
.pairings_pairing__1SO-O form:focus input.pairings_inputResult__3zRQ6 {
	visibility: visible;
}

.pairings_titleContainer__kEmNd {
	-webkit-animation: pairings_fade-in__3evHz 2s ease-out;
	        animation: pairings_fade-in__3evHz 2s ease-out;
}

div.pairings_topBanner__cesLX {	
	margin: 5px;
	width: unset;
}

@-webkit-keyframes pairings_fade-in__3evHz {
	0% {
		opacity: 0
	}
	50% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}

@keyframes pairings_fade-in__3evHz {
	0% {
		opacity: 0
	}
	50% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}

div.pairings_playerInfo__2uaDY {
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	box-sizing: border-box;
}
.pairings_playerInfo__2uaDY.pairings_selected__Q8rTa {
	background-color: darkblue;
}

.pairings_statusBarBackground__-Tdcy {
	grid-area: PairingBar;
	-webkit-animation: pairings_fade-in__3evHz 1s ease-out 1s both;
	        animation: pairings_fade-in__3evHz 1s ease-out 1s both;
}

div.pairings_statusBar__ksDQV {
	display: grid;
    grid-template-columns: 1fr 4fr 1fr;
	position: relative;
	-webkit-animation: pairings_slide-in-from-bottom__T3dHr 1s ease-out;
	        animation: pairings_slide-in-from-bottom__T3dHr 1s ease-out;
}

div.pairings_switchStatusBar__2WNPO {
	display: grid;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	position: relative;
	-webkit-animation: pairings_slide-in-from-bottom__T3dHr 1s ease-out;
	        animation: pairings_slide-in-from-bottom__T3dHr 1s ease-out;
	border-radius: 15px;
	overflow: hidden;
}

@-webkit-keyframes pairings_slide-in-from-bottom__T3dHr {
	0% {
		bottom: -100%;
	}
	50% {
		bottom: -100%;
	}
	100% {
		bottom: 0%;
	}
}

@keyframes pairings_slide-in-from-bottom__T3dHr {
	0% {
		bottom: -100%;
	}
	50% {
		bottom: -100%;
	}
	100% {
		bottom: 0%;
	}
}

.pairings_waitButton__FABZl, .pairings_finishButton__1Oo46, div.pairings_nextButton__1JEMj, .pairings_undoButton__18r4y {
    display: -webkit-flex;
    display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 5px;
	width: 100%;
	box-sizing: border-box;
}

div.pairings_waitButton__FABZl {
	background-color: #1e1e1e;
}

div.pairings_undoButton__18r4y {
	cursor: pointer;
	background-color: darkred;
}

div.pairings_nextButton__1JEMj {
	cursor: pointer;
	background-color: darkgreen;
	position: relative;
}

.pairings_nextButton__1JEMj > svg {
    -webkit-animation: pairings_rotate__MHEsR 1s infinite linear;
            animation: pairings_rotate__MHEsR 1s infinite linear;
}
.pairings_nextButton__1JEMj .pairings_remainingDropWrapper__3qZjO {
	position: absolute;
	bottom: 100%;
	font-size: 14px;
	opacity: 0.9;
	transition: opacity 0.1s ease;
	z-index: 3;
	/* Non-hover settings */
	display: none;
	opacity: 0;
}
.pairings_nextButton__1JEMj:hover .pairings_remainingDropWrapper__3qZjO, .pairings_remainingDrop__3WlNQ:hover {
	display: grid;
	opacity: 1;
}

.pairings_remainingDrop__3WlNQ {
	background-color: rgb(15, 15, 25);
	padding: 10px;
	max-height: 500px;
	display: grid;
	grid-auto-rows: auto;
	grid-row-gap: 5px;
	row-gap: 5px;
	margin-bottom: 15px;
	font-size: 14px;
	white-space: nowrap;
	border-radius: 5px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

@-webkit-keyframes pairings_rotate__MHEsR {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

@keyframes pairings_rotate__MHEsR {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

.pairings_inputResult__3zRQ6 {
	padding: 5px;
	background-color: #585858;
	box-sizing: border-box;
	border: 2px #252526 solid;
	white-space: nowrap;
	color: white;
	-webkit-justify-content: center;
	        justify-content: center;
    text-align: center;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 5px;
}

.pairings_inputResult__3zRQ6 {
	background-color: #585858;
}

.pairings_submit__3xuBg {
	display: -webkit-flex;
	display: flex;
	-webkit-align-self: stretch;
	        align-self: stretch;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	background-image: none;
	cursor: pointer;
	box-sizing: border-box;
	border: 2px #252526 solid;
	padding: 0px;
	width: 100%;
	background-color: darkgreen;
	color: white;
}

div.pairings_pairingAddButton__3a93z, div.pairings_clearFiltered__1pIJ0 {
	background-color: #3c3c3c;
	border-radius: 15px;
	cursor: pointer;
	padding: 8px 0;
}

.pairings_pairingAddButton__3a93z:hover {
	background-color: darkgreen;
}

.pairings_manualPairing__2PBWv {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	        align-items: stretch;
	margin: 5px;
}

.pairings_manualResultsBridge__3ldol {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr repeat(3, 45px) 1fr;
	grid-template-areas:
		'white wR dash bR black';
	border-radius: 15px;
}

.pairings_scoreSpacer__1FUln {
	padding: 5px;
	background-color: transparent;
	box-sizing: border-box;
	white-space: nowrap;
	color: white;
	-webkit-justify-content: center;
	        justify-content: center;
    text-align: center;
	-webkit-align-items: center;
	        align-items: center;
	min-width: 45px;
	border: 2px rgb(45, 45, 45) solid;
}

.pairings_manualPairing__2PBWv .pairings_playerInfo__2uaDY {
	padding: 8px;
    background-color: #3c3c3c;
    border: none;
	box-shadow: none;
	box-sizing: border-box;
    color: white;
	background-color: #585858;
	width: 100%;
	text-align: center;
}
.pairings_playerInfo__2uaDY::selection {
	background-color: #1e1e1e;
}
.pairings_manualPairing__2PBWv .pairings_playerInfo__2uaDY:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.pairings_manualPairing__2PBWv .pairings_playerInfo__2uaDY:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.pairings_centre__yIN0s, .pairings_centre__yIN0s > button, .pairings_centre__yIN0s > div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
}

.pairings_centre__yIN0s:hover span.tooltip {
	opacity: 0.8;
}

.pairings_knockout__14lKY {
	margin-top: -60px;
}
.sidebar_sidebar__3Z89I {
	white-space: pre-wrap;
	position: relative;
	-webkit-animation: sidebar_slide-in-from-left__3kaGk 1s ease-out;
	        animation: sidebar_slide-in-from-left__3kaGk 1s ease-out;
	padding: 5px;
	width: 100%;
	box-sizing: border-box;
}
.sidebar_sidebar__3Z89I.sidebar_players__3X4vY {
	grid-area: Players;
}
.sidebar_sidebar__3Z89I.sidebar_newPlayers__2MILk {
	grid-area: NewPlayers;
}

.sidebar_stage__14IEV {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	justify-self: stretch;
	-webkit-align-self: stretch;
	        align-self: stretch;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
	overflow: hidden;
}

@-webkit-keyframes sidebar_slide-in-from-left__3kaGk {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

@keyframes sidebar_slide-in-from-left__3kaGk {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

.sidebar_playersSection__3_kKf {
	height: 100%;
	box-sizing: border-box;
	border-radius: 15px;
	padding: 5px;
	overflow-x: hidden;
}
.sidebar_playersSection__3_kKf.sidebar_skel__1_Yn- {
	color: transparent;
}
.sidebar_playersSection__3_kKf.sidebar_skel__1_Yn- input::-webkit-input-placeholder {
	color: transparent;
}
.sidebar_playersSection__3_kKf.sidebar_skel__1_Yn- input:-ms-input-placeholder {
	color: transparent;
}
.sidebar_playersSection__3_kKf.sidebar_skel__1_Yn- svg, .sidebar_playersSection__3_kKf.sidebar_skel__1_Yn- input::placeholder {
	color: transparent;
}

.sidebar_playersSection__3_kKf::-webkit-scrollbar {
	width: 10px;
	background-color: rgba(85, 85, 85, 0.3);
}
.sidebar_playersSection__3_kKf::-webkit-scrollbar-track {
	background-color: transparent;
}
.sidebar_playersSection__3_kKf::-webkit-scrollbar-corner {
	display: none;
}
.sidebar_playersSection__3_kKf::-webkit-scrollbar-button {
	display: none;
}
.sidebar_playersSection__3_kKf::-webkit-resizer {
	display: none;
}
.sidebar_playersSection__3_kKf::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	-webkit-transition: background-color 2s ease;
	transition: background-color 2s ease;
	border-radius: 15px;
}
.sidebar_playersSection__3_kKf::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}

.sidebar_infoBox__1Oh8P {
	padding: 5px;
}
.sidebar_scoreSection__2Qh1c {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	align-self: stretch;
	grid-column: 1 / span 3;
	-ms-grid-column-span: 3;
	border-radius: 15px;
	overflow: hidden;
	transition: height 1s ease;
	grid-row-gap: 2px;
	row-gap: 2px;
}

.sidebar_playersSection__3_kKf form {
	border-radius: 15px;
	overflow: hidden;
}
.sidebar_playersSection__3_kKf .sidebar_isActive__3xy4p {
	z-index: 2;
}

.sidebar_scoreSection__2Qh1c input[type='text'] {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}
.sidebar_scoreSection__2Qh1c .sidebar_playerLine__1cnKs {
	-webkit-animation: sidebar_slide-down__apR1e 1s ease both;
	        animation: sidebar_slide-down__apR1e 1s ease both;
}
@-webkit-keyframes sidebar_slide-down__apR1e {
	from {
		max-height: 0px;
	}
	to {
		max-height: 60px;
	}
}
@keyframes sidebar_slide-down__apR1e {
	from {
		max-height: 0px;
	}
	to {
		max-height: 60px;
	}
}

#sidebar_addPlayerForm__1BL-S {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.sidebar_input__3Y8ld {
	padding: 8px;
	background-color: #3c3c3c;
	border: none;
	box-shadow: none;
	color: white;
	box-sizing: border-box;
	width: 100%;
}
.sidebar_isActive__3xy4p .sidebar_input__3Y8ld[name="firstName"] {
	padding-left: 30px;
}
.sidebar_input__3Y8ld::selection {
	background-color: #1e1e1e;
}

input[type=text]:focus,
input[type=number]:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
	background-color: #585858;
}
input[type=submit]:focus,input[type=submit]:active {
	border: none;
	outline: none;
}

input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.sidebar_playerLine__1cnKs.sidebar_skel__1_Yn- {
	opacity: 0.31;
	margin: 5px 0;
}
.sidebar_players__3X4vY .sidebar_playerLine__1cnKs:not(:hover):not(.hover) .sidebar_cancel__1DhJL,
.sidebar_players__3X4vY .sidebar_playerLine__1cnKs:not(:hover):not(.hover) .sidebar_edit__zaTlg,
.sidebar_players__3X4vY .sidebar_playerLine__1cnKs:not(:hover):not(.hover) .sidebar_activate__2-tDL {
	visibility: hidden;
}
div.sidebar_scoreSection__2Qh1c .sidebar_playerLine__1cnKs:hover .sidebar_cancel__1DhJL, div.sidebar_scoreSection__2Qh1c .sidebar_playerLine__1cnKs.hover .sidebar_cancel__1DhJL {
	visibility: visible;
}

.sidebar_submit__22Uk4, .sidebar_edit__zaTlg, .sidebar_cancel__1DhJL, .sidebar_activate__2-tDL, .sidebar_info__2QcrS {
	display: -webkit-flex;
	display: flex;
	-webkit-align-self: stretch;
	        align-self: stretch;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	background-image: none;
	cursor: pointer;
	font-weight: bold;
	border: none;
    padding: 0px;
    color: white;   
	width: 100%;
}

.sidebar_submit__22Uk4 {
	width: 100%;
	background-color: darkgreen;
	color: white;
}
.sidebar_playerLine__1cnKs .sidebar_info__2QcrS {
	background-color: #585858;
	color: white;
	cursor: help;
}

.sidebar_submit__22Uk4.sidebar_null__39tIA {
	background-color: grey;
	pointer-events: none;
}

.sidebar_playerInfo__12dBq {
	text-align: left;
	font-weight: 300;
	grid-area: firstName / firstName / lastName / lastName;
	position: relative;
	padding-left: 50px;
}
.sidebar_playerInfo__12dBq.sidebar_full__JrnzC {
	grid-column: 1 / -1;
}
div.sidebar_playerInfo__12dBq {
	padding-left: 30px;
}
.sidebar_playerInfo__12dBq.bold {
	font-weight: 600;
}

.sidebar_edit__zaTlg {
	width: 100%;
	grid-area: edit;
	background-color: gray;
}

.sidebar_activate__2-tDL {
	width: 100%;
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-area: cancel;
	background-color: darkgreen;
}

.sidebar_cancel__1DhJL {
	width: 100%;
	grid-column: 3 / span 1;
	-ms-grid-column-span: 1;
	grid-area: cancel;
	background-color: darkred;
}

.sidebar_playerLine__1cnKs {
	display: grid;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	width: 100%;
	justify-items: start;
	border-radius: 15px;
	overflow: hidden;
	grid-template-columns: 1fr 1fr 15% 15%;
	grid-template-areas:
		'firstName lastName edit cancel';
}

.sidebar_playerLine__1cnKs.sidebar_team__3Gw6e {
	grid-area:
		'firstName firstName edit cancel'
}

.sidebar_playerLine__1cnKs:hover .sidebar_playerInfo__12dBq{
	background-color: rgb(88, 88, 88);
	z-index: 1;
}

.sidebar_scoreSeparator__1zbAQ {
	display: -webkit-flex;
	display: flex;	
	border: 2px;
	padding: 5px 0 0 0;
}

.sidebar_sectionHeader__2UjkE {
	display: -webkit-flex;
	display: flex;
	justify-items: flex-start;
	padding: 7px;
	font-size: 13.3333px;
}

.sidebar_playerInactive__1Y9Kw {
	color: darkslategrey;
}
.sidebar_playerInfo__12dBq::after {
	content: ' ';
	width: 10px;
	height: 10px;
	left: 10px;
	box-sizing: border-box;
	border-radius: 50%;
	position: absolute;
}
.sidebar_isPlaying__2zQEt::after {
	background-color: var(--blue);
}
.sidebar_isUnpaired__6NAoB::after {
	background-color: var(--red);
}

form {
	width: 100%;
	padding: 0;
	margin: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
}

/* Bottom Banner */

.sidebar_topBanner__3fw_E { 
	width: 100%;
	-webkit-align-self: start;
	        align-self: start;
	background-color: #1e1e1e;
	box-sizing: border-box;
	border: 2px #252526 solid;
	padding: 5px;
	white-space: nowrap;
}

.sidebar_bottomBanner__ZFfBX {
	grid-area: PlayerBar;
    -webkit-animation: sidebar_slide-in-from-left__3kaGk 1s ease-out;
            animation: sidebar_slide-in-from-left__3kaGk 1s ease-out;
	position: relative;
}

.sidebar_striped__dRdNU {
	background: repeating-linear-gradient(
		45deg,
		rgba(56, 20, 20, 0.5),
		rgb(56, 20, 20, 0.5), 10px,
		rgb(20, 20, 20, 0.5) 10px,
		rgb(20, 20, 20, 0.5) 20px
	);
}
.sidebar_searchResults__3jJ2m {
	height: 120px;
}

.sidebar_shroud__2mM6p {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.333;
	z-index: 1;
	top: 0px;
	left: 0px;
}
div.intro_container__1InVN {
	-webkit-animation: intro_centre-split__en0Qz 0.333s ease;
	        animation: intro_centre-split__en0Qz 0.333s ease;
	width: 45%;
	min-width: 500px;
}

@-webkit-keyframes intro_centre-split__en0Qz {
	from {
		-webkit-transform: scaleY(0);
		        transform: scaleY(0)
	}
	to {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1)
	}
}

@keyframes intro_centre-split__en0Qz {
	from {
		-webkit-transform: scaleY(0);
		        transform: scaleY(0)
	}
	to {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1)
	}
}

.intro_section__1sHwR {
	margin: 1em 0 0.5em 0;
	font-weight: bold;
}

.intro_content__1rnrZ div.keyField {
	background-color: transparent;
}

.intro_content__1rnrZ div[class^='profile_row'] > div:not(.keyField),
.intro_content__1rnrZ div[class^='profile_row'] > div:not(.keyField) > div {
	border-radius: 15px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	-webkit-align-self: end;
	        align-self: end;
}
.intro_content__1rnrZ form[class^='components_form'] > input {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	outline: none;
}

.intro_content__1rnrZ div[class^='profile_row'] {
	margin: 6px 0;
	grid-column-gap: 6px;
	-webkit-column-gap: 6px;
	        column-gap: 6px;
}

.intro_templates__3Vo7N {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	margin: 10px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 10px;
	row-gap: 10px;
}

.intro_templates__3Vo7N > div {
	height: 190px;
	background-color: rgba(40, 40, 75, 0.5);
	border-radius: 15px;
	padding: 10px;
	display: grid;
	grid-template-rows: 150px 1fr;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: center;
}

.intro_templates__3Vo7N > div > svg {
	font-size: 120px;
	justify-self: center;
}

/* Depth: 3 */

.intro_set__WLAB1 {
	transition: max-height 0.333s ease;
	max-height: 700px;
	overflow: hidden;
}
.intro_set__WLAB1.intro_collapsed__3VkzO {
	max-height: 0px;
}

.intro_divider__3Qwx9 {
	display: grid;
	grid-template-columns: 1fr auto auto 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	cursor: pointer;
	font-weight: 300;
	font-size: small;
	margin-top: 2em;
}
.intro_divider__3Qwx9 .intro_angle__o5cLk {
	transition: -webkit-transform 0.333s ease;
	transition: transform 0.333s ease;
	transition: transform 0.333s ease, -webkit-transform 0.333s ease;
}
.intro_divider__3Qwx9 hr {
	width: 100%;
	height: 1px;
	background-color: rgba(150, 150, 150, 0.5);
	border: none;
}
.intro_divider__3Qwx9 svg:not(.intro_rotated__2Gz2p) {
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
}
.alert_shade__1O-5R {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(28, 28, 28, 0.5);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	z-index: 3;
	pointer-events: all;
}

.alert_blurred__2W47o {
	background-image: linear-gradient(45deg, rgba(28, 28, 28, 1), transparent);
	background-color: rgba(28, 28, 50, 0.5);
	-webkit-backdrop-filter: blur(4px);
	        backdrop-filter: blur(4px);
}

.alert_container__2heSX {
	width: 38.1%;
	height: 38.1%;
	position: absolute;
	padding: 5px;
	z-index: 3;
	background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.05), rgba(150, 150, 200, 0.01));
	background-color: rgba(15, 15, 25, 0.8);
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	        align-items: stretch;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
}

.alert_container__2heSX.alert_tall__Wusvc {
	height: 90%
}
.alert_container__2heSX.alert_wide__1Tt0o {
	width: 75%;
}

.alert_alertBox__-kpK4 {
	padding: 10px;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
		'title'
		'textBox'
		'buttons';
	z-index: 3;
	border-radius: 15px;
	overflow: hidden;
	width: 100%;
}

.alert_title__W4aFN {
	grid-area: title;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	background-color: #1e1e1e;
	box-sizing: border-box;
	padding: 5px;
	border-radius: 15px;
}

.alert_error__wKYnQ {
	color: red;
}

.alert_textBox__ATagI {
	grid-area: textBox;
	margin: 5px;
	padding: 5px 30px;
	display: block;
	text-align: left;
	overflow: scroll;
	font-weight: 300;
	box-sizing: border-box;
	width: unset;
}

.alert_textBox__ATagI ul {
	-webkit-padding-start: 20px;
	        padding-inline-start: 20px;
}

.alert_response__1RX0q {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: row;
}

.alert_buttonRow__1z51H {
	grid-area: buttons;
	display: grid;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
}
.alert_buttonRow__1z51H:last-child button {
	padding: 8px;
}

.alert_buttonRow__1z51H > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.alert_buttonRow__1z51H > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.alert_buttonRow__1z51H button {
	border: none;
    color: white;
}

button.alert_NO__2Bl9f {
	background-color: darkred
}

button.alert_OK__1hQGN {
	grid-column-end: yes;
}

button.alert_ERROR___uQVx {
	background-color: darkred;
}

button.alert_YES__pKi26 {
	background-color: darkgreen;
}

.alert_loading__37zfR {
	font-size: 5vw;
}

.alert_loading__37zfR > svg {
	-webkit-animation: alert_rotate__3F2E_ 1s linear infinite;
	        animation: alert_rotate__3F2E_ 1s linear infinite;
}
@-webkit-keyframes alert_rotate__3F2E_ {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}
@keyframes alert_rotate__3F2E_ {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

div.menu_column__4oCn8 {
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	display: grid;
	grid-template-rows: 1fr auto 1fr;
	-webkit-justify-content: center;
	        justify-content: center;
	justify-items: center;
	-webkit-align-items: center;
	        align-items: center;
}

div.menu_leftColumn__13Ey0 {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}

div.menu_rightColumn__2D55A {
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

/* Depth: 3 */
div.menu_container__4epvu {
	width: 100%;
	height: 100%;
	background-color: rgba(55, 55, 55, 0.5);
	-webkit-animation: menu_slide-in-from-left__225li 1s ease-out;
	        animation: menu_slide-in-from-left__225li 1s ease-out;
	position: relative;
}

.menu_profile__3-O4- {
	grid-area: Settings / Settings / Errors / Errors;
	box-sizing: border-box;
    height: 100%;
    width: 100%;    
	position: relative;
	overflow: hidden;
	padding: 5px;
    grid-column: unset;
	grid-row: unset;
	display: grid;
	grid-template-rows: auto 1fr;
}

/* Depth: 4 - lines */

.menu_line__2oByf {
	z-index: -1;
	position: absolute;
	transition: all 2s ease;
	background-color: black;
	height: 4px;
}
.menu_quick__3lLva .menu_line__2oByf {
	transition: unset;
}

.menu_row__q-PZk {
	grid-column: 1 / span 2;
	-ms-grid-column-span: 2;
	width: 100%;
	background-color: rgb(25, 25, 25);
	grid-template-columns: 75% 25%;
}

.menu_listWrapper__1_77c {
	overflow: hidden;
	-webkit-transform: scaleY(-1);
	        transform: scaleY(-1);
	transition: height 0.5s ease;
	width: 100%;
}

.menu_listWrapper__1_77c > * {
	-webkit-transform: scaleY(-1);
	        transform: scaleY(-1);
}

div.menu_clickButton__1F_HI {
	display: -webkit-flex;
	display: flex;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	-webkit-align-self: flex-start;
	        align-self: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

div.menu_clickButton__1F_HI:hover {
	background-color: #3c3c3c;
}

/* Depth: 5 - Dot */

.menu_dotContainer__ze3l6 {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
}
.menu_column__4oCn8 .menu_dotContainer__ze3l6 {
	-webkit-align-self: flex-start;
	        align-self: flex-start;
}

.menu_dot__3Dq9b {
	display: -webkit-flex;
	display: flex;
	margin: 20px 0;
	font-size: 30px;
	padding: 5px;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	border: 4px solid #0c0c0c;
	box-sizing: border-box;
	border-radius: 30px;
	background-color: #1e1e1e;
	background-image: repeating-linear-gradient(45deg,
		#1e1e1e8a,
		#1e1e1e8a, 10px,
		#2a2a2a8a 10px,
		#2a2a2a8a 20px
	);
	cursor: pointer;
}
.menu_column__4oCn8 .menu_dot__3Dq9b {
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.menu_dot__3Dq9b.menu_reverse__3czd3 {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}
.menu_column__4oCn8 .menu_dot__3Dq9b.menu_reverse__3czd3 {
	-webkit-flex-direction: column-reverse;
	        flex-direction: column-reverse;
}
.menu_dot__3Dq9b#create {
	background-color: rgb(0, 120, 0);
}
.menu_dot__3Dq9b#roundRobin {
	background-color: rgb(120, 0, 0);
}
.menu_dot__3Dq9b#knockout {
	background-color: rgb(0, 0, 120);
}
.menu_dot__3Dq9b#refresh {
	background-color: rgb(0, 0, 120);
}
.menu_dot__3Dq9b#close {
	background-color: rgb(120, 0, 0);
}
.menu_dot__3Dq9b#back {
	position: relative;
	right: -10px;
}

.menu_dotDescription__19QYi {
	font-family: 'Amatic SC', cursive;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	-webkit-align-items: center;
	        align-items: center;
	white-space: pre;
}

.menu_icon__20xPO {
	height: 100%;
	padding: 5px;
}
.menu_icon__20xPO#FaUndoAlt {
	font-size: 25px;
	padding: calc((30px - 25px) / 2 + 5px);
}

.menu_dotDescription__19QYi {
	max-width: 0px;
	overflow: hidden;
	transition: max-width 0.1s ease, max-height 0.1s ease;
}
.menu_dotContainerLoaded__3YmWC:hover .menu_dotDescription__19QYi, .menu_dotContainerLoaded__3YmWC .menu_dotDescription__19QYi.menu_descriptionSelected__2aKX- {
	max-width: 35ch;
	margin: 0 10px;
}
.menu_column__4oCn8 .menu_dotDescription__19QYi {
	max-width: 0px;
	max-height: 0px;
}
.menu_column__4oCn8 .menu_dotContainerLoaded__3YmWC:hover .menu_dotDescription__19QYi {
	max-height: 2em;
	margin: 0 10px;
}

div.menu_close__3eKoV:hover {
	background-color: darkred;
}

/* Sidebar */

.menu_sidebar__l1_lg {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-auto-flow: row;
    height: 100%;
    width: 100%;
}

@-webkit-keyframes menu_slide-in-from-left__225li {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

@keyframes menu_slide-in-from-left__225li {
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
}

div.menu_logo__3MwZ9 {
	width: 85%;
	-webkit-animation: menu_float-down__1GhiB 0.5s ease-out;
	        animation: menu_float-down__1GhiB 0.5s ease-out;
}
.menu_dotUnloaded__2DT-h {
	-webkit-animation: menu_expand__1E6M- 1.5s ease-out;
	        animation: menu_expand__1E6M- 1.5s ease-out;
}
.menu_quick__3lLva div.menu_logo__3MwZ9 {
	-webkit-animation: unset;
	        animation: unset;
}
.menu_quick__3lLva .menu_dotUnloaded__2DT-h {
	-webkit-animation: unset;
	        animation: unset;
}

@-webkit-keyframes menu_float-down__1GhiB {
	from {
		top: -80px;
	}
	to {
		top: 0px;
	}
}

@keyframes menu_float-down__1GhiB {
	from {
		top: -80px;
	}
	to {
		top: 0px;
	}
}

@-webkit-keyframes menu_expand__1E6M- {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0)
	}
	75% {
		-webkit-transform: scale(0);
		        transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1)
	}
}

@keyframes menu_expand__1E6M- {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0)
	}
	75% {
		-webkit-transform: scale(0);
		        transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1)
	}
}

@-webkit-keyframes menu_briefly-expand__ex-pm {
	from {
		-webkit-transform: scale(1);
		        transform: scale(1)
	}	
	to {
		-webkit-transform: scale(2);
		        transform: scale(2)
	}
}

@keyframes menu_briefly-expand__ex-pm {
	from {
		-webkit-transform: scale(1);
		        transform: scale(1)
	}	
	to {
		-webkit-transform: scale(2);
		        transform: scale(2)
	}
}

/* Profile settings */

div.menu_license1__1o4qd {
    background-color: darkred;
}

div.menu_license2__1N8w4 {
    background-color: darkgreen;
}

div.menu_license3__2EL0m {
    background-color: darkblue;
}

.menu_scrollable__39r7X {
	border-radius: 10px;
	overflow-x: hidden;
	margin: 10px 0;
}
