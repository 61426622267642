/* styles common to login and menu */

div.canvas {
	height: 100%;
	width: 100%;
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: 30% 40% 30%;
	grid-template-rows: 100%;
}


div.column {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

div.logo {
	width: 50%;
	max-width: 300px;
	display: grid;
	position: relative;
	grid-template: 100% / 100%;
}

div.logo hr.loadingLine {
	width: 100%;
	margin: 5% 0;
	border: 5px solid #972a15;
}

img.staticLogo {
	width: 100%;
	position: relative;
	grid-column: 1 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}

img.loadingGif {
    position: absolute;
    width: 66.6%;
    max-width: 200px;
    transform: rotate(-33deg);
    opacity: 1;
    left: 6%;
    top: 15%;
	position: relative;
	grid-column: 1 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}

img.crownOutline {
    position: absolute;
    width: 71%;
    max-width: 213px;
    transform: rotate(-32deg);
    opacity: 1;
    z-index: 1;
    left: 5%;
    top: 13%;
	position: relative;
	grid-column: 1 / span 1;
	-ms-grid-column-span: 1;
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}

@media screen {
	.logo {
		animation: fade-in 1 0.5s linear;
	};
	.loadingLine {
		animation: grow-right infinite 0.68s linear;
	}
}

@keyframes fade-in {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}

@keyframes grow-right {
	from {
		transform: rotate(0deg) scaleX(0) scaleY(1)
	}
	to {
		transform: rotate(0deg) scaleX(1) scaleY(1)
	}
}