div.column {
	height: auto;
}

div.container {
	width: 100%;
	padding: 10px;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);
	background-color: rgba(15, 15, 25, 0.9);
}

.nameContainer {
	display: grid;
	grid-auto-flow: row;
	column-gap: 15px;
	font-family: "Roboto", Whitney, "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding-bottom: 10px;
	justify-content: right;
	align-items: flex-end;
}
.name {
	font-size: 36px;
	font-weight: 600;
}
.description {
	font-size: 12px;
	font-weight: 200;
	padding: 5px;
	box-sizing: border-box;
}
.nameContainer svg {
	margin: 0 20px;
}
.nameContainer .copyright {
	font-size: 10px;
}

div.initialiseButton {
	display: grid;
	height: 100%;
	width: 100%;
	min-height: 150px;
	overflow: hidden;
}

div.forgottenContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 8px;
	box-sizing: border-box;
	height: 100%;
}
.forgottenContainer > div:first-child {	
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.forgottenContainer > div:last-child {	
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

div.forgotten {
	font-size: 12px;
	background-color: #1a1a1a;
	color: grey;
	justify-content: center;
	align-items: center;
	display: flex;
	outline: none;
	box-sizing: border-box;
	cursor: pointer;
}

.form {
	display: grid;
	grid-template-rows: repeat(4, 1fr);
	row-gap: 8px;
	height: 100%;
}

.form > .input {
	font-size: 12px;
	height: 100%;
	width: 100%;
	overflow: hidden;
	border-radius: 15px;
	display: flex;
	padding: 5px 10px;
	background-color: #585858;
	color: white;
	border: none;
	outline: none;
	box-sizing: border-box;
}

input.submit, button.submit {
	height: 100%;
	width: 100%;
	background-color: darkgreen;
	color: white;
	justify-content: center;
	border-radius: 15px;
	border: none;
	outline: none;
	cursor: pointer;
}

.input.back, .input.text {
	cursor: pointer;
	justify-content: center;
	padding: 6px;
	background-color: transparent;
}

.loginSection {
	position: relative;
	overflow: hidden;
	border-radius: 15px;
}

.loginBackground {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 100%;
	align-items: center;
}

.foreground {
	display: flex;
	position: absolute;
	width: 66.6%;
	height: 100%;
	opacity: 1;
}

.loginForeground {
	left: 0px;
	-webkit-transition: all 0.68s ease-out;
	-moz-transition: all 0.68s ease-out;
	 -ms-transition: all 0.68s ease-out;
	  -o-transition: all 0.68s ease-out;
		 transition: all 0.68s ease-out;
}

.registerForeground {
	left: 33.3%;
	-webkit-transition: all 0.68s ease-out;
	-moz-transition: all 0.68s ease-out;
	 -ms-transition: all 0.68s ease-out;
	  -o-transition: all 0.68s ease-out;
		 transition: all 0.68s ease-out;
}

div.flip {
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: transparent;
	opacity: 1;
	transition: opacity 0.333s ease;
}
div.flip:hover {
	background-color: unset;
}

.flip > div {
    margin: 2px 0;
}
div.flip.hidden {
	opacity: 0;
}
div.guest {
	display: none;
	position: absolute;
	bottom: 2px;
	right: 2px;
	background-color: rgba(255, 166, 0, 0.5);
	border-radius: 15px;
	padding: 5px 10px;
	height: unset;
	font-size: x-small;
}

div.messageBar {
	display: flex;
	width: 100%;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
    border: 2px #252526 solid 0.0;
	box-shadow: none;
	justify-content: center;
	color: red;
}