.blank, .check, .ban {
	cursor: pointer;
	pointer-events: all;
}

.blank {
	color: rgb(15, 15, 15);
}

.check {
	color: lightgreen;
}

.ban {
	color: indianred;
}