/* Depth: 1 */

.container {
	position: relative;
	z-index: 2;
}

/* Depth: 2 */

.header {
	display: flex;
	flex-direction: row;
	position: relative;
	border-radius: 15px;
}
div.header:hover:not(.sent) {	
	color: var(--col-accent1-hover);
}


.wrapper {
	position: absolute;
	overflow: hidden;
	width: 100%;
	transition: height 0.33s ease;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: 1;
}

/* Depth: 3 */

.list {
	margin: 2px 0 0 0;
	padding: 0px;
	width: 100%;
	display: grid;
	row-gap: 1px;
	background-color: rgb(69, 69, 69)
}

.list li {
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: #1e1e1e;
	padding: 5px;
	box-sizing: border-box;
	cursor: pointer;
	font-size: small;
}

.list li:hover {
	background-color: rgb(69, 69, 69)
}