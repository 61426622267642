/* Depth: 1 */

.body {
	display: grid;
	grid-template-rows: auto 1fr minmax(30vh, auto);
}

/* Paste */
.stage {
	grid-column: 1 / -2;
}

.stage > div:global(.container) {
	grid-column: 1 / span 2;
	-ms-grid-column-span: 2;
	height: 100%;
}

.table {
	grid-row-start: 2;
	grid-row-end: -1;
}

.headerSection, .errors {
	min-height: 40vh;
}
.headerSection {
	grid-row: 1 / span 1;
	-ms-grid-row-span: 1;
}
.errors {
	grid-row: 2 / span 1;
	-ms-grid-row-span: 1;
}
.errors .list {
	column-gap: 5px;
	grid-template-columns: auto 50px;
}
.errors .list :global(.button) {
	cursor: pointer;
}
.error:hover {
	background-color: indianred;
}

.pasteContainer {
	background-color: #2d2d2d;
	border-radius: 15px;
	height: 100%;
	overflow-x: scroll;
}
.paste {
	width: 100%;
	box-sizing: border-box;
	padding: 5px;
    flex-shrink: 0;
}

.subheader {
	border-radius: 15px;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 15px;
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	column-gap: 20px;
	align-items: center;
}
.subheader > * {
	box-sizing: border-box;
}
.pasteArea {
	border-radius: 15px;
	overflow: hidden;
	background-color: rgba(45, 45, 45);
	border-radius: 15px;
}

.form {
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 90% 10%;
}

.textarea, .output {
	border-radius: 15px;
	max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(33, 33, 33);
    height: 100px;
	color: white;
    position: relative;
	padding: 5px;
	resize: vertical;
}

button.submit, div.submit {
	padding: 10px 5px;
	height: unset;
	border-radius: 15px;
	border: none;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: green;
}

button.reset {
	background-color: rgb(0, 0, 49);
	margin: 5px 0;
}

button.clear {
	background-color: darkred;
	margin: 5px 0;
}

.playerTable {
	width: 100%;
}

div.parsedHeader {
	background-color: gold;
	color: black;
	border-radius: 5px;
	min-height: 2em;
	height: unset;
	z-index: 1;
}

.list {
	display: grid;
	grid-auto-rows: 1fr;
	row-gap: 5px;
	padding: 5px;
}

.tableHeader {
	min-height: 2em;
	transition: background-color 0.25s ease;
}
.tableHeader.dragEnter {
	background-color: blue;
}

.target {
	height: 100%;
	position: relative;
}

.placeholder {
	text-align: justify;
	padding: 20px;
	opacity: 0.4;
	font-weight: 300;
	pointer-events: none;
	max-height: 100%;
	overflow: hidden;
	font-family: monospace;
}

div.error {
	cursor: pointer;
	box-sizing: border-box;
}

div.errorCell {
	background-color: rgb(129, 25, 25);
}

div.highlighted {
	background-color: darkred;
}

div.description {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-weight: 500;
	font-size: 4vh;
}