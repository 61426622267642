

/* Draggable */

div.spacer {
	width: 100%;
	min-height: 60px;
	animation: fade-in 1s ease 1s;
}
.spacer.highlighted {
	display: flex;
	position: relative;
	padding: 5px;
	box-sizing: border-box;
}
.spacer.highlighted::after {
	position: absolute;
	border: 1.5px dashed rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
	margin: 5px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(50, 50, 100, 0.3);
	border-radius: 5px;
	content: '+';
	display: flex;
	align-items: center;
	justify-content: center;
}
@keyframes fade-in {
	from {
		opacity: 0%
	}
	to {
		opacity: 100%;
	}
}

/* Depth: 2 */

div.listElement {
	display: grid;
	column-gap: 2px;
	grid-template-columns: 1fr;
	grid-auto-flow: column;
}
div.listElement.columns {
	grid-template-columns: auto 1fr;
}

div.listElement, div.buttonContainer {
	transition: margin-top 0.02s ease;
}

div.number {
	background-color: #1e1e1e;
}
.number.ignored {
	color: transparent;
}

div.buttonContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 2px;
	overflow: hidden;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

div.buttonContainer > div {
	display: flex;
	flex-direction: row;
	padding: 5px;
	justify-content: center;
	cursor: pointer;
}

div.buttonContainer > div > svg {
	padding: 5px;
}
div.buttonContainer > div > img {
	height: 1em;
}
div.tbReset {
	background-color: rgb(0, 52, 156);
}
div.tbSubmit {
	background-color: darkgreen;
}