body {
	color: white;
	justify-items: center;
}
*:not(.scrollable):not(.scrollable-x)::-webkit-scrollbar {
	display: none;
}
*:not(.scrollable):not(.scrollable-x) {
	scrollbar-width: none;
}

#root {
	height: 100%;
	--blue: rgb(5, 5, 30);
	--red: indianred;
}

.stage {
	height: 100vh;
	width: 100vw;
	border: 0px;
	position: fixed;
	text-align: center;
	overflow: scroll;
	background-image: url('https://web.scorchapp.co.uk/images/leaves-texture.png');
	background-color: #111;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.stage.electron {
	height: calc(100vh - 30px);
	top: 30px;
}

.shade {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(28, 28, 28, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	pointer-events: all;
}
.blurred {
	background-image: linear-gradient(45deg,
		rgba(28, 28, 28, 1),
		rgba(150, 150, 200, 0.1)
	);
	background-color: rgba(28, 28, 50, 0.5);
	backdrop-filter: blur(4px);
}
.tournamentFloat {
	position: absolute;
	z-index: 3;
}

button.null, div.null {
	background-color: grey!important;
	pointer-events: none!important;
	opacity: 0.5;
}

.scrollable {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
}
.scrollable-x:not(.scrollable) {
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
}
.scrollable.scrollable-x {
	overflow-x: auto;
	overflow-y: auto;
}
.scrollable::-webkit-scrollbar, .scrollable-x::-webkit-scrollbar {
	cursor: default;
	width: 10px;
	background-color: rgba(85, 85, 85, 0);
}
.scrollable::-webkit-scrollbar:hover, .scrollable-x::-webkit-scrollbar:hover {
	background-color: rgba(85, 85, 85, 0.2);
}
.scrollable::-webkit-scrollbar-track, .scrollable-x::-webkit-scrollbar-track {
	cursor: default;
	background-color: transparent;
}
.scrollable::-webkit-scrollbar-corner, .scrollable-x::-webkit-scrollbar-corner {
	display: none;
}
.scrollable::-webkit-scrollbar-button, .scrollable-x::-webkit-scrollbar-button {
	display: none;
}
.scrollable::-webkit-resizer, .scrollable-x::-webkit-resizer {
	display: none;
}
.scrollable::-webkit-scrollbar-thumb , .scrollable-x::-webkit-scrollbar-thumb  {
	background-color: rgba(85, 85, 85, 0.5);
	transition: background-color 2s ease;
	border-radius: 15px;
}
.scrollable::-webkit-scrollbar-thumb:hover, .scrollable-x::-webkit-scrollbar-thumb:hover {
	background-color: rgba(85, 85, 85, 0.8);
}

.hidden {
	display: none!important;
}

button > * {
    pointer-events: none;
}

.bold {
	font-weight: bold;
}

.code {
	font-family: 'Courier New', Courier, monospace;
	background-color: rgba(5, 5, 5, 0.5);
	display: flex;
	width: 100%;
	justify-content: center;
	box-sizing: border-box;
	padding: 5px;
	user-select: all;
}

.skel {
	color: transparent;
}

button.button, div.button, a.button {	
	display: flex;
	justify-self: stretch;
	height: 100%;
	white-space: pre-wrap;
	box-sizing: border-box;
	background-color: #1e1e1e;
	padding: 5px 0;
	justify-content: center;
	align-content: center;
	align-items: center;
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.button {
	font-size: 14px;
	height: 100%;
    display: flex;
    flex-direction: row;
	align-items: center;
}
*::selection {
	background-color: rgba(199, 199, 199, 0.226);
}

.button > svg {
	padding: 0 5px;
}

div.button:hover, a.button:hover, button.button:hover {
	background-color: rgb(69, 69, 69);
}

div.fieldBox {
	display: flex;
	text-align: left;
	align-items: center;
    background-color: rgb(33, 56, 56);
	padding: 8px;
	white-space: break-word;
	overflow-x: scroll;
	font-size: 13.3333px;
	cursor: default;
	position: relative;
}

div.playerInfo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	padding: 8px;
	white-space: break-word;
	height: 100%;
	font-size: 13.3333px;
	cursor: pointer;
}

div.playerInfo:hover, div.playerInfo.hover {
	background-color: rgb(88, 88, 88);
}

div.topBanner {
	width: 100%;
	align-self: start;
	background-color: #1e1e1e;
	box-sizing: border-box;
	padding: 5px;
	border-radius: 15px;
	min-height: 32px;
	align-items: center;
}

div.bottomBannerBackground {
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
}

div.bottomBanner {
    height: 100%;
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	column-gap: 5px;
	width: 100%;
	align-self: end;
	box-sizing: border-box;
	justify-content: stretch;
	border-radius: 15px;
	background-color: rgb(45, 45, 45);
	padding: 5px;
	box-sizing: border-box;
}
.bottomBanner > *:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.bottomBanner > *:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

input[type='submit'] {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.input {
	display: flex;
	padding: 5px 0 5px 5px;
	background-color: #585858;
	box-sizing: border-box;
	border: 2px #252526 solid;
	box-shadow: none;
	color: white;
}
.container {
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 5px;
}

.tooltip {
	background-color: black;
	color: white;
	text-align: center;
	padding: 5px;
	border-radius: 6px;
	position: absolute;
	top: -220%;
	width: 120px;
	left: calc(50% - 60px);
	opacity: 0;
	z-index: 1;
	transition: opacity 0.25s;
	pointer-events: none;
}

.tooltip::after {
	content: " ";
	position: absolute;
	top: 100%; /* At the bottom of the tooltip */
	left: 50%;
	margin-left: -15px;
	border-width: 10px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.loading > svg {
    animation: rotate 1s infinite linear
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
}
*:focus {
	outline: none;
}

.highlighted {
	color: rgb(207, 176, 0);
}

/* Toggle Switch */

/* The switch - the box around the slider */
.toggleSwitch {
	position: relative;
	max-width: 60px;
	justify-self: center;
}

/* Hide default HTML checkbox */
.toggleSwitch input.check {
	display: none;
}

/* The slider */
.toggleSlider {
	position: relative;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 80px;
	background-color: rgb(65, 65, 65);
	-webkit-transition: .4s;
	transition: .4s;
	min-height: 20px;
	display: flex;
	margin: 0 10px;
}

.toggleSlider:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 30.8%;
	left: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input.check:checked+.toggleSlider {
	background-color: rgb(180, 180, 180);
}

input.check:focus+.toggleSlider {
	box-shadow: 0 0 1px rgb(65, 65, 65);
}

input.check:checked+.toggleSlider:before {
	left: calc(100% - 30.8% - 4px);
}

/* Rounded sliders */
.toggleSlider.round {
	border-radius: 34px;
}

.toggleSlider.round:before {
	border-radius: 34px;
}

.switchContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.switchContainer > label {
	display: flex;
	justify-content: center;
	align-items: center;
}

.switchContainer label div:not(:first-child) {
	font-size: 14px;
	font-weight: 300;
}

button.submit, button.reset {
	padding: 10px 5px;
	height: unset;
	border-radius: 5px;
	border: none;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	cursor: pointer;
}
button.reset {
	background-color: rgb(0, 0, 49);
}
button.submit {
	background-color: darkgreen;
}

.triangle {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 0;
	height: 0;
	border-style: solid;
}

#chessBoard {
	transform: scaleX(-1);
}

/* Images */

img#lichess {
	filter: invert(0.8);
}
img#ecf {
	filter: invert(0.5);
}

/* Components */


/* Collapsable Section */

.sectionContainer {
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	margin-bottom: 5px;
}
.sectionContainer:not(.scrollable) {
	overflow: hidden;
}